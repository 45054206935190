<template>
    <v-dialog v-model="dial" max-width="600px" @keydown.esc="cierra">
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon v-if="activaproductos" large color="red"
                    @click="activaproductos = false">mdi-keyboard-return</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-1">
            <v-autocomplete v-if="!activaproductos && $store.state.configuracion.ordencategorias"" class=" mb-n4"
                v-model="producto_sele" autofocus :items="$store.state.productos" item-text="nombre" item-value="id"
                label="Buscar Productos" clearable menu-props="auto" outlined dense ref="buscarField"
                append-icon="mdi-magnify" @change="prod_selecto">
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>
                            <strong>{{ item.categoria }}</strong> - {{ item.nombre }} - <strong class="red--text">S/ {{
                                item.precio }}</strong>
                        </v-list-item-title>
                    </v-list-item-content>
                </template></v-autocomplete>
            <v-simple-table v-if="!activaproductos && $store.state.configuracion.ordencategorias" fixed-header
                max-width="600px" class="pa-1">
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>

                        <v-row class="mt-1 mb-4 mx-auto" style="text-align:center" dense>
                            <v-col v-for="item in arrayiconos" :key="item.id" cols="6" class="pa-1" md="4" sm="4"
                                xs="4">
                                <v-card height="80" @click.prevent="iraproductos(item)">
                                    <v-card-text class="red--text" style="font-size:medium">
                                        <span class="black--text">{{ item.nombre }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </tbody>
                </template>
            </v-simple-table>

            <div v-if="activaproductos || !$store.state.configuracion.ordencategorias">
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field class="mb-n1 mt-n2" outlined dense v-model="buscar" append-icon="mdi-magnify"
                        label="Buscar" single-line hide-details :autofocus='!$store.state.esmovil'></v-text-field>
                </v-card-title>

                <v-simple-table fixed-header height="60vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nombre
                                </th>
                                <th class="text-left">
                                    Stock
                                </th>
                                <th class="text-left">
                                    Precio
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id" @click="abre_cantidad(item)">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.stock }}</td>
                                <td>{{ item.precio }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-card>
        <v-dialog v-model="dialo_cantidad" max-width="350px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialo_cantidad = !dialo_cantidad">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card>
                <v-card-title>
                    <v-textarea v-model="observacion_can" auto-grow filled color="deep-purple" label="Observacion"
                        rows="1"></v-textarea>
                    <v-chip-group column multiple v-model="observacionesSeleccionadas"
                        active-class="deep-purple--text text--accent-4">
                        <v-chip v-for="(item, index) in obs_gen" :key="index" filter outlined>
                            {{ item.nombre }}
                        </v-chip>
                    </v-chip-group>
                </v-card-title>
                <v-card-title class="mt-n8">
                    <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD"
                        @focus="$event.target.select()" @keydown.enter="agrega_con_cantidad()"></v-text-field>
                </v-card-title>

                <v-btn class="mt-n6" color="red" @click="agrega_con_cantidad()" block>OK</v-btn>

            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="progress" max-width="200">
            <v-card class="pa-12">
                <v-progress-circular :rotate="90" :size="100" :width="15" color="primary"
                    indeterminate></v-progress-circular>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import store from '@/store/index'

export default {

    data() {
        return {
            dial: false,
            arrayiconos: [],
            tamañoarray: 0,
            categoriaselecta: '',
            activaproductos: false,
            buscar: '',
            progress: false,
            dialo_cantidad: false,
            cantidad: 1,
            producto_selecto: '',
            obs_gen: [],
            observacionesSeleccionadas: [],
            observacion_can: '',
            producto_sele: '',

        }
    },
    created() {
        this.dial = true
    },
    mounted() {
        this.initialize()
    },
    methods: {
        initialize() {
            //this.activaproductos=false
            this.arrayiconos = store.state.categorias
        },
        iraproductos(item) {
            this.buscar = ''
            this.categoriaselecta = item.nombre
            if (this.listafiltrada != '') {
                this.activaproductos = true
            }

        },
        prod_selecto(valor) {
            if (!valor) return; // Si no hay valor seleccionado, no hacer nada
            this.$nextTick(() => {
                this.producto_sele = "";
            });
            // Buscar el producto seleccionado en la lista del store
            const producto = store.state.productos.find(p => p.id === valor);
            if (producto) {
                // Filtrar las observaciones disponibles
                const obs_filt = store.state.observaciones.filter(e =>
                    e.modo == 'general' && e.categorias.includes(producto.categoria)
                );
                this.obs_gen = obs_filt.length > 0 ? obs_filt.flatMap(e => e.lista) : [];
                this.observacionesSeleccionadas = [];
                this.observacion_can = ''
                this.cantidad = 1;
                this.producto_selecto = producto;
                this.dialo_cantidad = true;
            }
        },
        abre_cantidad(valor) {
            if (valor.stock <= 0) {
                alert('SIN STOCK')
                return
            }

            if (valor) {
                // Filtrar las observaciones disponibles
                const obs_filt = store.state.observaciones.filter(e =>
                    e.modo == 'general' && e.categorias.includes(valor.categoria)
                );
                this.obs_gen = obs_filt.length > 0 ? obs_filt.flatMap(e => e.lista) : [];
                this.observacionesSeleccionadas = [];
                this.observacion_can = ''
                this.cantidad = 1;
                this.producto_selecto = valor;
                this.dialo_cantidad = true;
            }
        },
        agrega_con_cantidad() {
            if (this.cantidad == '' || this.cantidad == 0) {
                this.cantidad = 1
            }

            var cant = parseFloat(this.cantidad)
            this.producto_selecto.cantidad = cant
            let observacionesNombres = this.observacionesSeleccionadas.map(index => {
                const obs = this.obs_gen[index]; // Buscar el nombre en `obs_gen`
                return obs ? obs.nombre : index; // Si encuentra, usa el nombre; si no, deja el valor original
            });
            this.producto_selecto.observacion = this.observacion_can ? [this.observacion_can, ...observacionesNombres] : [...observacionesNombres];
            if (this.producto_selecto.controstock) {
                if (parseFloat(this.producto_selecto.stock) < parseFloat(this.cantidad)) {
                    alert('Producto sin Stock')
                    return
                }
            }
            this.dialo_cantidad = false
            this.$emit('array', this.producto_selecto)
            if (this.producto_selecto.es_combo) {
                for (var i = 0; i < this.producto_selecto.lista_combo.length; i++) {
                    var dd = this.producto_selecto.lista_combo[i]
                    const producto = store.state.productos.find(p => p.id == dd.cod_producto);
                    if (producto) {
                        producto.operacion = 'GRATUITA'
                        producto.cantidad = dd.cantidad * this.cantidad
                        producto.precio = 1
                        producto.observacion = ''
                        this.$emit('array', producto)
                    }
                }
            }
            if (store.state.configuracion.persistencia_catalogo) {
                this.dialog_progress()

            } else {
                this.cierra()
                this.activaproductos = false
                this.buscar = ''
            }


        },
        dialog_progress() {
            this.progress = true
            setTimeout(() =>
                this.progress = false, 400);
            this.buscar = ''
        },
        cierra() {
            this.activaproductos = false
            this.$emit('cierra', false)
        },
    },
    computed: {
        listafiltrada() {
            var invent = store.state.productos
            if (store.state.configuracion.ordencategorias) {
                return invent.filter((item) =>
                    (item.activo) == true)
                    .filter((item) => (item.categoria)
                        .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
                    .filter((item) => (item.nombre)
                        .toLowerCase().includes(this.buscar.toLowerCase()))
            } else {
                return invent.filter((item) =>
                    (item.activo) == true)
                    .filter((item) => (item.nombre)
                        .toLowerCase().includes(this.buscar.toLowerCase()))
            }

        }
    }
}
</script>
