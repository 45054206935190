<template>
    <div class="pa-3">
        <h3>CARTA ADICIONAL</h3>
        <v-row>
            <v-col cols="4">
                <v-btn rounded elevation="10" block color="info" @click="obtenordenproducto()" small>NUEVO</v-btn>
            </v-col>
            <v-col cols="4">
                <v-btn rounded elevation="10" block color="error" @click="irCategorias()" small>CATEGORIAS</v-btn>
            </v-col>
            <v-col cols="4">
                <v-btn rounded elevation="10" block color="success" @click="transferir_carta()" small>USAR CARTA</v-btn>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="12">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>

        <v-simple-table class="elevation-4" fixed-header height="60vh" dense>
            <template v-slot:default>
                <thead>

                    <tr>
                        <th class="text-left">
                            id
                        </th>
                        <th class="text-left">
                            Categoria
                        </th>
                        <th class="text-left">
                            Nombre
                        </th>
                        <th class="text-left">
                            Stock
                        </th>
                        <th class="text-left">
                            Precio
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:80%;">{{ item.id }}</td>
                        <td style="font-size:80%;">{{ item.categoria }}</td>
                        <td style="font-size:80%;">{{ item.nombre }}</td>
                        <td style="font-size:80%;">{{ item.stock }}</td>
                        <td style="font-size:80%;">{{ item.precio }}</td>
                        <td style="font-size:80%;">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="green" @click="editar(item)">mdi-lead-pencil</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="dialogo" max-width="500px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    ID : {{ id }}
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="eliminar()">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6">
                        <v-select :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col v-if="$store.state.configuracion.codbarras" cols="12" md="12" sm="12">
                        <v-text-field dense class="mb-n7" v-model="codbarra" label="Cod Barras"
                            append-icon="mdi-barcode-scan" autofocus></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-autocomplete dense v-model="categoria" :items="arraycategoria" menu-props="auto" hide-details
                            label="Categoria" clearable></v-autocomplete>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-select dense v-model="medida" :items="$store.state.medidas" menu-props="auto" hide-details
                            label="Medida"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n2">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="nombre" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" dense v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="stock_min" label="Stock min"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n7">
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="precio" label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense type="number" v-model="costo" label="Costo"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-3">

                    <v-col cols="12" class="mt-n7">
                        <v-select dense v-model="grupo_obs" :items="lista_obs" chips label="Grupo Obs."
                            multiple></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n10" v-if="$store.state.baseDatos.restaurante">
                    <v-col cols="6">
                        <v-switch v-model="cocina" label="Impr.Cocina"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="barra" label="Impr.Barra"></v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-n10" v-if="$store.state.baseDatos.restaurante">
                    <v-col cols="6">
                        <v-switch v-model="cocina2" label="Cocina 2"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="barra2" label="Barra 2"></v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-n10">
                    <v-col cols="6">
                        <v-switch v-model="cargoxconsumo" label="Cargo x consumo"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="controstock" label="Control Stock"></v-switch>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

    </div>
</div></template>

<script>
import {
    allProductos2,
    eliminaProducto2,
    obtenContador,
    sumaContador,
    allCategorias_2,
    editaProducto2,
    allProductos,
    allCategorias,
    copia_productos2,
    copia_productos1,
    copia_categoria1,
    copia_categoria2,
} from '../../db'
import store from '@/store/index'
export default {

    data: () => ({
        sumacon: false,
        dialogoFiltro: false,
        dial_setting: false,
        buscar: '',
        desserts: [],
        id: '',
        categoria: '',
        nombre: '',
        medida: 'UNIDAD',
        stock: '',
        precio: '0',
        costo: '',
        cocina: '',
        arraycategoria: [],
        dialogo: false,
        arraytipoProducto: ['BIEN', 'SERVICIO'],
        arrayfiltroStock: ['incluir 0', 'excluir 0'],
        filtrostock: 'incluir 0',
        arrayfiltroestado: ['TODOS', 'ACTIVO', 'INACTIVO'],
        filtroestado: 'ACTIVO',
        tipoproducto: "BIEN",
        arrayOperacion: [
            'GRAVADA',
            'EXONERADA'
        ],
        operacion: "GRAVADA",
        icbper: false,
        controstock: false,
        codbarra: '',
        activo: true,
        barra: false,
        cargoxconsumo: false,
        stock_min: '',
        item_selecto: [],
        lista_obs: [],
        grupo_obs: [],
        cocina2: false,
        barra2: false,
    }),
    async beforeCreate() {
        var snapshot = await allCategorias_2().once("value")
        snapshot.forEach((item) => {
            this.arraycategoria.push(item.val().nombre)
        })
        var snap = store.state.observaciones
        snap.forEach((item) => {
            this.lista_obs.push(item.nombre)
        })
    },

    mounted() {
        allProductos2().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allProductos2().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            allProductos2().on("value", this.onDataChange)
            return this.desserts.filter((item) => (item.categoria + item.id + item.nombre)
                .toLowerCase().includes(this.buscar.toLowerCase()))
        }
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                let key = item.key
                array.push(data);
            });
            this.desserts = array

        },
        async obtenordenproducto() {
            store.commit("dialogoprogress")
            this.sumacon = true
            var snapshot = await obtenContador().once("value")
            if (snapshot.exists()) {
                this.id = snapshot.val().ordenproducto
                this.codbarra = ''
                this.categoria = '1'
                this.nombre = ''
                this.medida = 'UNIDAD'
                this.stock = 1
                this.precio = 0
                this.costo = 0
                this.cocina = false
                this.barra = false
                this.tipoproducto = 'BIEN'
                this.operacion = 'GRAVADA'
                this.icbper = false
                this.dialogo = true
                this.activo = true
                this.controstock = false
                this.cargoxconsumo = false
                this.grupo_obs = []
                this.cocina2 = false
                this.barra2 = false
                this.stock_min = 1
                if (Boolean(store.state.configuracion.operacion)) {
                    this.operacion = store.state.configuracion.operacion
                }
            }
            store.commit("dialogoprogress")
        },
        editar(val) {
            this.item_selecto = val
            this.sumacon = false
            this.id = val.id
            this.activo = val.activo
            this.codbarra = val.codbarra
            this.categoria = val.categoria
            this.nombre = val.nombre
            this.medida = val.medida
            this.stock = val.stock
            this.precio = val.precio
            this.costo = val.costo
            this.cocina = val.cocina
            this.tipoproducto = val.tipoproducto
            this.operacion = val.operacion
            this.icbper = val.icbper
            this.barra = val.barra
            this.controstock = val.controstock
            this.cargoxconsumo = val.cargoxconsumo
            if (!Boolean(val.grupo_obs)) {
                val.grupo_obs = []
            }
            if (!Boolean(val.stock_min)) {
                val.stock_min = 1
            }
            this.grupo_obs = val.grupo_obs
            this.stock_min = val.stock_min
            if (!Boolean(val.cocina2)) {
                val.cocina2 = false
            }
            if (!Boolean(val.barra2)) {
                val.barra2 = false
            }
            this.cocina2 = val.cocina2
            this.barra2 = val.barra2
            this.dialogo = true
        },
        async save() {
            if (this.stock == '') {
                this.stock = 0
            }
            if (this.categoria == 1 || this.categoria == '') {
                alert("DEBE CONSIDERAR UNA CATEGORIA VALIDA")
                return
            }
            if (this.precio == 0) {
                alert("EL PRECIO NO PUEDE SER IGUAL A 0")
                return
            }
            editaProducto2(this.id, "id", this.id)
            editaProducto2(this.id, "activo", this.activo)
            editaProducto2(this.id, "codbarra", this.codbarra)
            editaProducto2(this.id, "nombre", this.nombre.trim())
            editaProducto2(this.id, "categoria", this.categoria)
            editaProducto2(this.id, "medida", this.medida)
            editaProducto2(this.id, "stock", this.stock)
            editaProducto2(this.id, "precio", this.precio)
            editaProducto2(this.id, "costo", this.costo)
            editaProducto2(this.id, "cocina", this.cocina)
            editaProducto2(this.id, "tipoproducto", this.tipoproducto)
            editaProducto2(this.id, "operacion", this.operacion)
            editaProducto2(this.id, "icbper", this.icbper)
            editaProducto2(this.id, "barra", this.barra)
            editaProducto2(this.id, "controstock", this.controstock)
            editaProducto2(this.id, "cargoxconsumo", this.cargoxconsumo)
            editaProducto2(this.id, "grupo_obs", this.grupo_obs)
            editaProducto2(this.id, "stock_min", this.stock_min)
            editaProducto2(this.id, "cocina2", this.cocina2)
            await editaProducto2(this.id, "barra2", this.barra2)
            if (this.sumacon == true) {
                this.sumacontador()
            }
            this.dialogo = false
        },

        async eliminar() {
            if (confirm('SEGURO QUE DESEA ELIMINAR?')) {
                await eliminaProducto2(this.item_selecto.id)
                this.dialogo = false
            }
        },
        irCategorias() {
            this.$router.push({
                name: 'categorias2'
            })
        },
        sumacontador() {
            sumaContador("ordenproducto", parseInt(this.id) + 1)
        },
        async transferir_carta() {
            if (confirm('SEGURO QUE DESEA USAR ESTA CARTA COMO PRINCIPAL?')) {
                store.commit("dialogoprogress")
                var prod2 = await allProductos2().once("value")
                var cate2 = await allCategorias_2().once("value")
                var cate1 = await allCategorias().once("value")
                var prod1 = await allProductos().once("value")
                await copia_categoria2(cate1.val())
                await copia_categoria1(cate2.val())
                await copia_productos2(prod1.val())
                await copia_productos1(prod2.val())
                store.commit("dialogoprogress")
            }
        }
    },
}
</script>
