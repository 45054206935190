<template>
    <v-dialog v-model="dial" class="mx-auto" max-width="550" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large @click="cierre()" color="red">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h3>Total = S/.{{ total }}</h3>

                <v-spacer></v-spacer>
                <v-icon large @click="visualizar()" color="green" v-if="false">mdi-eye</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-radio-group class="mt-n1 mx-auto " v-model="tipocomprobante" row>
                <v-radio label="Nota" value="T"></v-radio>
                <v-radio label="Boleta" value="B"></v-radio>
                <v-radio label="Factura" value="F"></v-radio>
            </v-radio-group>
            <template>
                <v-row class="mt-n3" dense>
                    <v-col cols="4" xs="4">
                        <v-select outlined dense v-model="documento" :items="documentos" menu-props="auto" hide-details
                            label="Tipo Doc"></v-select>
                    </v-col>
                    <v-col cols="8" xs="8">
                        <v-text-field autofocus outlined dense type="number" v-model="numero" label="Numero"
                            append-icon="mdi-magnify" @click:append="BuscarDocumento()"
                            @keyup.enter="BuscarDocumento()"></v-text-field>
                    </v-col>
                </v-row>

                <v-text-field class="mt-n4" outlined dense v-model="nombreCompleto"
                    label="Nombres Completos"></v-text-field>
                <v-text-field class="mt-n3" outlined dense v-model="direccion" label="Direccion"></v-text-field>
                <v-text-field class="mt-n3" v-if="$store.state.configImpresora.refcliente" outlined dense
                    v-model="refcliente" label="Referencia"></v-text-field>
                <v-text-field class="mt-n3" v-if="$store.state.configImpresora.telfcliente" outlined type="number" dense
                    v-model="telfcliente" label="Telefono"></v-text-field>
                <v-text-field class="mt-n3" outlined v-if="$store.state.configuracion.comisiones"
                    append-icon="mdi-magnify" @click:append="BuscarEmpleado()" @keyup.enter="BuscarEmpleado()" dense
                    v-model="nom_empleado" label="Empleado"></v-text-field>
                <v-text-field class="mt-n3" outlined dense v-model="observacion" label="Observacion"></v-text-field>

                <v-row class="mt-n3">
                    <v-col cols="4">
                        <v-btn block color="primary" @click="cobrar()" small>
                            EFECTIVO
                        </v-btn>
                    </v-col>
                    <v-col cols="4">
                        <v-btn block color="error" @click="cobroCredito()" small
                            v-if="$store.state.configuracion.ventas_credito">
                            Credito
                        </v-btn>
                    </v-col>
                    <v-col cols="4">
                        <v-btn block color="warning" @click="otros()" small>
                            OTROS
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

        </v-card>
        <v-dialog v-model="dial_pagos" max-width="490" transition="dialog-bottom-transition">
            <v-card class="pa-3">
                {{ valida_modo_pago }}
                <div class="mt-6">
                    <v-row class="mt-n3" dense v-for="item in modopagos" :key="item.modo">
                        <v-col cols="12">
                            <v-row dense no-gutters>
                                <v-col cols="1">

                                </v-col>
                                <v-col cols="2">
                                    <v-img class="ml-n4" height="35" width="35" :src="busca_ico(item.nombre)"
                                        @click="cambia_modo_pago(item)"></v-img>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field class="ml-n10" :autofocus="item.nombre == 'EFECTIVO'" prefix="S/."
                                        outlined dense v-model="item.monto" type="number"
                                        :label="item.nombre"></v-text-field>
                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>
                </div>
                <v-btn block color="error" @click="cobrar()" small>
                    COBRAR
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogoempleado" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoempleado = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="buscar" append-icon="mdi-magnify" label="Search" single-line hide-details
                        dense></v-text-field>
                </v-card-title>

                <v-simple-table fixed-header height="400px" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    NOMBRE
                                </th>
                                <th class="text-left">
                                    TELEFONO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltradaempleados" :key="item.id" @click="agregaEmpleado(item)">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.telefono }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogocredito" max-width="500px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogocredito = !dialogocredito">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon large @click="nuevacuota()" color="green">mdi-plus</v-icon>
                    <v-icon large @click="eliminacuota()" color="red">mdi-minus</v-icon>
                </v-system-bar>
            </div>
            <v-card class="mb-1" height="350px">
                <v-simple-table fixed-header height="280px" dense>
                    <thead>

                        <tr>
                            <th class="text-left">
                                Cuota
                            </th>
                            <th class="text-left">
                                monto
                            </th>
                            <th class="text-left">
                                Vence
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in cuotasCredito" :key="item.modo">
                            <td colspan="3">
                                <v-row class="mt-1" dense>
                                    <v-col cols="4">
                                        <v-text-field disabled type="number" v-model="item.numero" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field type="number" v-model="item.importe" dense></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field type="date" v-model="item.vencimiento" dense></v-text-field>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="cobrar()">Finalizar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
        <clientes @array="agregacliente($event)" />

    </v-dialog>
</template>

<script>

import {
    allEmpleados,
    obtenContador,
    nuevoCliente
} from '../../db'

import { enviaDocumentoApiSunat } from '../../servidorsunat'
import store from '@/store/index'
import axios from "axios"
import clientes from '@/components/dialogos/dialogoClientes'
import moment from 'moment'
export default {
    name: 'caja',

    components: {
        clientes,
    },
    props: {
        cabecera: [],
        items: [],
        cliente: []
    },
    data() {
        return {
            dialogocredito: false,
            dial: false,
            genera_pdf: false,
            dialogoempleado: false,
            dial_pagos: false,
            empleadoslista: [],
            buscar: '',
            documentos: ['DNI', 'RUC', 'Pasaporte', 'Carnet de Extranjeria'],
            documento: 'DNI',
            tipocomprobante: 'T',
            numero: '',
            nombreCompleto: '',
            direccion: '',
            departamento: '',
            provincia: '',
            distrito: '',
            ubigeo: '',
            telefono: '',
            refcliente: '',
            telfcliente: '',
            observacion: '',
            nom_empleado: '',
            dni_empleado: '',
            modopagos: [],
            total: '',
            cuotasCredito: []
        }
    },
    created() {
        this.tipocomprobante = store.state.configuracion.defecto
        console.log(this.cliente)
        if (this.cliente != '' && this.cliente != undefined) {
            console.log(this.cliente)
            this.numero = this.cliente.numero
            this.documento = this.cliente.documento
            this.nombreCompleto = this.cliente.nombreCompleto
            this.direccion = this.cliente.direccion
            this.tipocomprobante = this.cliente.tipocomprobante
            this.telfcliente = this.cliente.telefono
        }

        this.total = this.cabecera.total - this.cabecera.descuentos
        this.modopagos = []
        var modos = store.state.modopagos
        for (var i = 0; i < modos.length; i++) {
            var data = modos[i]
            var mont = ''
            this.modopagos.push({
                nombre: data,
                monto: mont
            })
        }
        this.dial = true
    },
    computed: {
        listafiltradaempleados() {
            return this.empleadoslista.filter((item) =>
                (item.alias + item.nombre + item.telefono)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        },
        valida_modo_pago() {
            var a = this.modopagos.find(id => String(id.nombre) == String('EFECTIVO'))
            var resta = this.total
            for (var i = 1; i < this.modopagos.length; i++) {
                resta = resta - this.modopagos[i].monto
            }

            if (resta < 0) {
                store.commit('dialogosnackbar', 'MONTOS NO COINCIDEN')
            } else {
                this.modopagos[0].monto = resta
            }
            return
        },
    },
    methods: {
        cobroCredito() {
            this.cuotasCredito = []
            var totalcuenta = this.total
            var totaldescuentos = this.cabecera.descuentos
            // var fec_venc = moment(this.fecha_cuota()) / 1000
            this.cuotasCredito.push({
                numero: '001',
                importe: (totalcuenta - totaldescuentos).toFixed(2),
                vencimiento: this.conviertefecha(this.fecha_cuota()),
                estado: 'pendiente',
                fecha_modificacion: moment().unix(),
                vendedor: store.state.permisos.correo.slice(0, -13)
            })
            this.dialogocredito = true
        },
        nuevacuota() {
            var numerocuota = (parseInt(this.cuotasCredito.length + 1)).toString().padStart(3, 0)

            this.cuotasCredito.push({
                numero: numerocuota,
                importe: 0,
                vencimiento: this.conviertefecha(this.fecha_cuota()),
                estado: 'pendiente',
                fecha_modificacion: moment().unix(),
                vendedor: store.state.permisos.correo.slice(0, -13)
            })
        },
        eliminacuota() {
            this.cuotasCredito.pop()
        },
        conviertefecha(date) {
            return moment.unix(date).format('YYYY-MM-DD')
        },
        fecha_cuota() {
            var fecha = moment(String(new Date())).add(30, 'd') / 1000
            return fecha
        },
        async cobrar() {
            store.commit("dialogoprogress")
            if (this.valida_pagos() != parseFloat(this.total)) {
                alert('Debe ingresar monto correcto')
                store.commit("dialogoprogress")
                return
            }
            if (this.tipocomprobante == "F" && this.numero.length != 11 ||
                this.tipocomprobante == "F" && this.nombreCompleto == '' ||
                this.documento == 'RUC' && this.numero.length != 11) {
                alert('DEBE CONSIDERAR DATOS DEL CLIENTE')
                store.commit("dialogoprogress")
                return
            }
            let snapshot = await obtenContador().once("value")
            var contadores = snapshot.val()
            var auto = ""
            if (this.documento == "DNI") {
                var doccliente = "1" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.documento == "RUC") {
                var doccliente = "6" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.documento == "Pasaporte") {
                var doccliente = "7" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.documento == "Carnet de Extranjeria") {
                var doccliente = "4" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
            }
            if (this.tipocomprobante == "B") { //Catálogo No. 01: Código de Tipo de documento
                var cod_comprobante = '03' //01-factura -- 03-boleta -- 07-notaCred -- 08-notadebit -- 
                var serie = store.state.seriesdocumentos.boleta
                var correlativo = contadores.ordenboleta
            }
            if (this.tipocomprobante == "F") { //Catálogo No. 01: Código de Tipo de documento 
                var cod_comprobante = '01' //01-factura -- 03-boleta -- 07-notaCred -- 08-notadebit -- 
                var serie = store.state.seriesdocumentos.factura
                var correlativo = contadores.ordenfactura
            }
            if (this.tipocomprobante == "T") {
                var cod_comprobante = '00'
                var serie = store.state.seriesdocumentos.ticket
                var correlativo = contadores.ordenticket
                this.cabecera.estado = 'aprobado'
                auto = "NO"
            }
            if (this.nombreCompleto == '') {
                this.nombreCompleto = 'CLIENTES VARIOS'
            }
            if (this.numero == '') {
                this.numero = '00000000'
                this.documento = "DNI"
                var doccliente = "1"
            } else {
                this.guarda_cliente()
            }
            this.cabecera.forma_pago = "Contado"
            this.cabecera.cuotas = ''
            if (this.cuotasCredito != '') {
                var vencimientodoc = moment(String(this.cuotasCredito[this.cuotasCredito.length - 1].vencimiento)) / 1000
                this.cabecera.vencimientoDoc = vencimientodoc
                this.cabecera.cuotas = this.cuotasCredito
                this.cabecera.forma_pago = "Credito"
            }
            this.cabecera.serie = serie
            this.cabecera.correlativoDocEmitido = correlativo
            this.cabecera.numeracion = serie + '-' + correlativo
            this.cabecera.tipoDocumento = this.documento
            this.cabecera.cod_tipoDocumento = doccliente
            this.cabecera.dni = this.numero
            this.cabecera.cliente = this.nombreCompleto
            this.cabecera.direccion = this.direccion
            this.cabecera.telefono = this.telefono || ''
            this.cabecera.observacion = this.observacion
            this.cabecera.nomempleado = this.nom_empleado
            this.cabecera.dniempleado = this.dni_empleado
            this.cabecera.referenciacliente = this.refcliente || ''
            this.cabecera.tipocomprobante = this.tipocomprobante
            this.cabecera.cod_comprobante = cod_comprobante
            this.cabecera.automata = auto
            this.cabecera.modopago = this.modopagos
            var arrayCabecera = this.cabecera
            var array_item = this.items
            var array = {
                arrayCabecera: arrayCabecera,
                array_item: array_item,
                control_stock: store.state.configuracion.stock_comandas
            }
            if (arrayCabecera.tipocomprobante != 'T') {
                enviaDocumentoApiSunat(arrayCabecera, array_item)
            }
            // await cobrar_js(arrayCabecera, array_item)
            await this.api_rest(array, 'cobrar_js')
            this.$emit('terminar', arrayCabecera)
        },
        api_rest(data, metodo) {

            var a = axios({
                method: 'POST',
                url: 'https://us-central1-mitienda-f5ef8.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/mitienda-f5ef8/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                console.log(response.data)
                return response
            })
            return a
        },
        otros() {
            this.dial_pagos = true
        },
        async guarda_cliente() {
            var array = {
                activo: true,
                id: this.numero,
                tipodoc: this.documento,
                documento: this.numero,
                nombre: this.nombreCompleto,
                correo: '',
                departamento: this.departamento,
                provincia: this.provincia,
                distrito: this.distrito,
                ubigeo: this.ubigeo,
                direccion: this.direccion,
                telefono: this.telefono,
                alias: '',
                nota: 'agregado automaticamente',
                referencia: this.refcliente,
            }
            await nuevoCliente(array.id, array)
        },
        BuscarDocumento() {
            if (this.numero == '') {
                store.commit("dialogoClientesnuevos")
            } else {
                if (this.documento == "RUC" || this.documento == "DNI" &&
                    this.numero.length == 8 || this.numero.length == 11) {
                    store.commit("dialogoprogress")

                    if (this.numero.length == 11) {
                        this.documento = "RUC"
                    } else {
                        this.documento = "DNI"
                    }
                    var cliente = store.state.clientes.find(id => String(id.documento) === String(this.numero))
                    if (Boolean(cliente)) {
                        this.cliente_selecto = cliente
                        this.nombreCompleto = cliente.nombre
                        this.direccion = cliente.direccion
                        this.refcliente = cliente.referencia
                        this.telfcliente = cliente.telefono
                        store.commit("dialogoprogress")
                    } else {
                        this.consultaApiPeru()
                    }
                } else {
                    store.commit('dialogosnackbar', 'Documento Invalido')
                }
            }
        },
        agregacliente(data) {
            this.cliente_selecto = data
            this.documento = data.tipodoc
            this.numero = data.documento
            this.nombreCompleto = data.nombre
            this.telfcliente = data.telefono
            this.direccion = data.direccion
            this.refcliente = data.referencia
        },
        consultaApiPeru() {
            var self = this
            var token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress"),
                    self.llenardatos(response.data.data)
                ))
        },
        async llenardatos(data) {
            this.departamento = store.state.baseDatos.departamento
            this.provincia = store.state.baseDatos.provincia
            this.distrito = store.state.baseDatos.distrito
            this.ubigeo = store.state.baseDatos.ubigeo
            if (this.documento == 'DNI') {
                this.nombreCompleto = data.nombre_completo
                if (Boolean(data.direccion_completa)) {
                    this.direccion = data.direccion_completa
                }
            }
            if (this.documento == 'RUC') {
                this.nombreCompleto = data.nombre_o_razon_social
                if (Boolean(data.direccion_completa)) {
                    this.direccion = data.direccion_completa
                    this.departamento = data.departamento
                    this.provincia = data.provincia
                    this.distrito = data.distrito
                    this.ubigeo = data.ubigeo_sunat
                }
            }
        },
        async BuscarEmpleado() {
            var snapshot = await allEmpleados().once("value")
            let array = [];
            snapshot.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.empleadoslista = array
            this.dialogoempleado = true

        },
        agregaEmpleado(item) {
            this.nom_empleado = item.nombre
            this.dni_empleado = item.documento
            this.dialogoempleado = false
        },

        busca_ico(data) {
            var iconos = store.state.iconos_pagos.find(item => item.nombre == data)
            return iconos.icono
        },
        cambia_modo_pago(val) {
            for (var i = 0; i < this.modopagos.length; i++) {
                this.modopagos[i].monto = ''
            }
            var pos = this.modopagos.map(e => e.nombre).indexOf(val.nombre)
            this.modopagos[pos].monto = this.total
        },
        valida_pagos() {
            var suma = 0
            for (var i = 0; i < this.modopagos.length; i++) {
                var data = this.modopagos[i]
                if (data.monto != '') {
                    suma = suma + parseFloat(data.monto)
                }
            }
            return suma
        },
        cierre() {
            this.$emit('cierre', false)
        }

    },

}
</script>
