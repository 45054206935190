import jspdf from "jspdf";
import "jspdf-autotable";
import store from "@/store/index";
import { consultaArchivo } from "./db";
import { NumerosALetras } from "numero-a-letras";
import QR from "qrcode-base64";
import moment from "moment";
import axios from "axios";
let impresoras = "Caja1";
export const genera_precuenta = async (
  cabecera,
  items,
  tama,
  detalle,
  responsable
) => {
  switch (tama) {
    case "80":
      var resp = await impresion80(cabecera, items, detalle, responsable);
      break;
    case "58":
      var resp = await impresion58(cabecera, items, detalle);
      break;
  }
  return resp;
};
function impresion58(cabecera, arraydatos) {
  var arraycabe = cabecera;
  var linea = parseInt(store.state.configImpresora.msuperior);
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var array = arraydatos;
  var emision = "Hora : " + moment(String(new Date())).format("hh:mm A");
  var guardadocumento = store.state.configImpresora.guardadocumento;
  var lMargin = 1; //left margin in mm
  var rMargin = 1; //right margin in mm
  var pdfInMM = 55; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var pageCenter = pdfInMM / 2;

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM],
  });
  doc.setTextColor(10);
  doc.text(".", 0, linea);
  linea = linea + 4;

  doc.setFontSize(10);
  doc.setFont("Helvetica", "Bold");
  if (arraycabe.delivery) {
    var texto = doc.splitTextToSize("DELIVERY", pdfInMM - lMargin - rMargin);
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  } else {
    var texto = doc.splitTextToSize(
      "MESA N°" + arraycabe.id_mesa,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  }
  linea = linea + 3.5 * texto.length;
  doc.setFontSize(9);
  var texto = doc.splitTextToSize(emision, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");

  linea = linea + 3.5 * texto.length;

  doc.setFont("Helvetica", "");

  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 7;
  doc.text(separacion, pageCenter, linea, "center");

  //-----------------productos-----------------------
  var total = 0;
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    var obs = "";
    if (array[i].observacion != "") {
      obs = "\n" + array[i].observacion;
    }
    total = total + parseFloat(array[i].precio * array[i].cantidad);
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = array[i].nombre + " - S/." + array[i].precio + obs;
    nuevoArray[i][2] = parseFloat(array[i].precio * array[i].cantidad).toFixed(
      2
    );
  }

  doc.autoTable({
    margin: { top: linea - 8, left: 0 },
    styles: {
      fontSize: 8,
      cellPadding: 0.4,
      valign: "middle",
      halign: "center",
    },
    headStyles: { lineWidth: 0, minCellHeight: 9 },
    columnStyles: {
      0: { columnWidth: 8, halign: "center", valign: "middle" },
      1: { columnWidth: 28, halign: "left", valign: "middle" },
      2: { columnWidth: 20, halign: "center", valign: "middle" },
    },
    theme: ["plain"],
    head: [["Cant", "Descripcion", "P.T"]],
    body: nuevoArray,
  });

  let finalY = doc.previousAutoTable.finalY;
  linea = finalY + 3;
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 4;
  doc.setFont("Helvetica", "");
  doc.setFontSize(9);
  doc.text("Total : ", lMargin, linea);
  doc.text(
    "S./" +
      parseFloat(total)
        .toFixed(2)
        .toString(),
    lMargin + 20,
    linea
  );
  linea = linea + 3;
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  /* if (guardadocumento) {
     doc.save(fecha + '.pdf')
   } else {
     abre_dialogo_impresion(doc.output('bloburl'))
   }*/
  if (store.state.configImpresora.impresora_auto) {
    axios_imp(doc.output("arraybuffer"));
  } else {
    if (store.state.esmovil) {
      window.open(doc.output("bloburi"));
    } else {
      abre_dialogo_impresion(doc.output("bloburl"));
    }
  }
}
function impresion80(cabecera, arraydatos, detalle, responsable) {
  console.log(arraydatos);
  var arraycabe = cabecera;
  var linea = 5;
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var array = arraydatos;
  var emision = moment(String(new Date())).format("hh:mm A - DD/MM");
  var guardadocumento = store.state.configImpresora.guardadocumento;
  var lMargin = 3.5; //left margin in mm
  var rMargin = 2; //right margin in mm
  var pdfInMM = 75; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var pageCenter = pdfInMM / 2;

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [700, pdfInMM],
  });
  doc.setTextColor(10);
  doc.setFontSize(10);
  doc.setFont("Helvetica", "Bold");
  if (arraycabe.delivery) {
    var texto = doc.splitTextToSize(
      "DELIVERY " + arraycabe.id_mesa,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  } else {
    var texto = doc.splitTextToSize(
      "MESA N°" + arraycabe.id_mesa,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  }
  linea = linea + 3.5 * texto.length;
  doc.setFontSize(9);
  var texto = doc.splitTextToSize(emision, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3.5 * texto.length;

  var texto = doc.splitTextToSize(responsable, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3.5 * texto.length;

  doc.setFont("Helvetica", "");
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 7;
  doc.text(separacion, pageCenter, linea, "center");

  //-----------------productos-----------------------
  if (detalle) {
    var total = 0;
    var nuevoArray = new Array(array.length);
    for (var i = 0; i < array.length; i++) {
      var obs = "";
      console.log(array[i].observacion);
      if (array[i].observacion != "" && array[i].observacion != undefined) {
        obs = "\n" + array[i].observacion;
      }
      if (array[i].operacion != "GRATUITA") {
        total = total + parseFloat(array[i].precio * array[i].cantidad);
      }
      nuevoArray[i] = new Array(4);
      nuevoArray[i][0] = array[i].cantidad;
      nuevoArray[i][1] = array[i].nombre + " - S/." + array[i].precio + obs;
      if (array[i].operacion != "GRATUITA") {
        nuevoArray[i][2] = parseFloat(
          array[i].precio * array[i].cantidad
        ).toFixed(2);
      } else {
        nuevoArray[i][2] = "0.00";
      }
    }

    doc.autoTable({
      margin: { top: linea - 8, left: 1 },
      styles: {
        fontSize: 9,
        cellPadding: 0.4,
        valign: "middle",
        halign: "center",
      },
      headStyles: { lineWidth: 0, minCellHeight: 9 },
      columnStyles: {
        0: { columnWidth: 10, halign: "center" },
        1: { columnWidth: 45, halign: "left" },
        2: { columnWidth: 15, halign: "left" },
      },
      theme: ["plain"],
      head: [["Cant", "Descripcion", "P.T"]],
      body: nuevoArray,
    });
    let finalY = doc.previousAutoTable.finalY;
    linea = finalY + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 4;
    doc.setFont("Helvetica", "");
    doc.setFontSize(9);
    doc.text("Total : ", lMargin, linea);
    doc.text(
      "S./" +
        parseFloat(total)
          .toFixed(2)
          .toString(),
      lMargin + 30,
      linea
    );
    linea = linea + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
  } else {
    var nuevoArray = new Array(array.length);
    for (var i = 0; i < array.length; i++) {
      var obs = "";
      if (array[i].observacion != "") {
        obs = "\n" + array[i].observacion;
      }
      nuevoArray[i] = new Array(4);
      nuevoArray[i][0] = array[i].cantidad;
      nuevoArray[i][1] = array[i].nombre + obs;
    }
    doc.autoTable({
      margin: { top: linea - 8, left: 1 },
      styles: {
        fontSize: 9,
        cellPadding: 0.4,
        valign: "middle",
        halign: "center",
      },
      headStyles: { lineWidth: 0, minCellHeight: 9 },
      columnStyles: {
        0: { columnWidth: 10, halign: "center" },
        1: { columnWidth: 60, halign: "left" },
      },
      theme: ["plain"],
      head: [["Cant", "Descripcion"]],
      body: nuevoArray,
    });
    let finalY = doc.previousAutoTable.finalY;
    linea = finalY + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 3;
  }
  linea = linea + parseFloat(store.state.configImpresora.minferiorgeneral);
  doc.text(".", 0.5, linea);

  if (store.state.configImpresora.impresora_auto) {
    axios_imp(doc.output("arraybuffer"));
  } else {
    if (store.state.esmovil) {
      window.open(doc.output("bloburi"));
    } else {
      abre_dialogo_impresion(doc.output("bloburl"));
    }
  }
}
function abre_dialogo_impresion(blob) {
  var Ancho = screen.width;
  var Alto = screen.height;
  var A = (Ancho * 50) / 100;
  var H = (Alto * 50) / 100;
  var difA = Ancho - A;
  var difH = Alto - H;
  var tope = difH / 2;
  var lado = difA / 2;
  var Opciones =
    "status=no, menubar=no, directories=no, location=no, toolbar=no, scrollbars=yes, resizable=no, width=" +
    A +
    ", height=" +
    H +
    ", top=" +
    tope +
    ", left=" +
    lado +
    "";
  var w = window.open(blob, "_blank", Opciones);
  w.print();
}

function reportes(data, name) {
  var reader = new FileReader();
  reader.readAsDataURL(data);
  reader.onloadend = function() {
    var base64data = reader.result;
    var base64 = base64data.split(",")[1];
    Open(base64, name);
  };
}
const Open = (base64, name) => {
  var ip = store.state.configImpresora.ip_cocina;
  //var printWindow = window.open('http://'+ip+':8082/printreport?result=')
  var param = {
    file: base64,
    namefile: name,
    nameprinter: store.state.impresoras_restau.nom_cocina,
  };
  OpenWindowWithPost(
    "http://" + ip + ":8082/printer",
    "width=730,height=345,left=100,top=100,resizable=yes,scrollbars=yes",
    "NewFile",
    param
  );
};

const OpenWindowWithPost = (url, windowoption, name, params) => {
  var form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", name);

  for (var i in params) {
    if (params.hasOwnProperty(i)) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = i;
      input.value = params[i];
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  var w = window.open("post.htm", name, windowoption);
  form.submit();
  document.body.removeChild(form);
  setTimeout(function() {
    w.close();
  }, 600);
};

export const pc_print = (
  mesa,
  responsable,
  fecha,
  cuentatotal,
  array_cocina,
  array_barra,
  array_cocina2,
  array_barra2
) => {
  if (Boolean(store.state.configuracion.guarda_mesas)) {
    var data_mesa = {
      id: moment().unix(),
      fecha: moment().unix(),
      mesa: mesa,
      cocina: array_cocina,
      barra: array_barra,
    };

    //nuev_histo_mesa(data_mesa.id, data_mesa)
  }

  var nom_cocina = store.state.impresoras_restau.nom_cocina;
  var nom_barra = store.state.impresoras_restau.nom_barra;
  var nom_cocina2 = store.state.impresoras_restau.nom_cocina2;
  var nom_barra2 = store.state.impresoras_restau.nom_barra2;
  var impresion = [];
  if (array_cocina != "") {
    impresion.push({
      impresora: nom_cocina,
      formato: "pedido.lrf",
      mesa: mesa,
      responsable: responsable,
      fecha: fecha,
      total: cuentatotal,
      items: array_cocina,
    });
  }
  if (array_barra != "") {
    impresion.push({
      impresora: nom_barra,
      formato: "pedido.lrf",
      mesa: mesa,
      responsable: responsable,
      fecha: fecha,
      total: cuentatotal,
      items: array_barra,
    });
  }
  if (array_cocina2 != "") {
    impresion.push({
      impresora: nom_cocina2,
      formato: "pedido.lrf",
      mesa: mesa,
      responsable: responsable,
      fecha: fecha,
      total: cuentatotal,
      items: array_cocina2,
    });
  }
  if (array_barra2 != "") {
    impresion.push({
      impresora: nom_barra2,
      formato: "pedido.lrf",
      mesa: mesa,
      responsable: responsable,
      fecha: fecha,
      total: cuentatotal,
      items: array_barra2,
    });
  }

  var ticket = {
    impresion: impresion,
  };
  console.log(ticket);

  var a = reporte(ticket, mesa).then((r) => {
    return r;
  });
  return a;
};

function reporte(data, name) {
  console.log("entrooo");
  var a = new Promise((resolve, reject) => {
    var ip = store.state.configImpresora.ip_cocina;
    var printWindow = window.open(
      "http://" + ip + ":8082/printreport?result=" + btoa(JSON.stringify(data)),
      name,
      "left=5000,top=5000,width=0,height=0"
    );
    setTimeout(function() {
      printWindow.close();
      resolve(true);
    }, 1000);
  });

  return a;
}
async function axios_imp(pdfBuffer) {
  store.commit("dialogoprogress");
  let data = store.state.serv_imp;
  const impresoraEncontrada = data.impresoras.filter(
    (impresora) => impresora.tipo === impresoras
  );

  const nombresImpresoras = impresoraEncontrada.map(
    (impresora) => impresora.deviceId
  );
  console.log(impresoras, nombresImpresoras);
  if (nombresImpresoras.length > 0) {
    axios
      .post("http://" + data.ip + "/print", pdfBuffer, {
        headers: {
          "Content-Type": "application/pdf",
          printernames: JSON.stringify(nombresImpresoras),
        },
      })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      })
      .finally(() => {});
  } else {
    alert(`No se encontró una impresora con el tipo: ${impresoras}`);
  }
  await new Promise((resolve) => setTimeout(resolve, 500));
  store.commit("dialogoprogress");
}
