import jspdf from "jspdf";
import "jspdf-autotable";
import store from "@/store/index";
import axios from "axios";
import { consultaArchivo } from "./db";
import { NumerosALetras } from "numero-a-letras";
import QR from "qrcode-base64";
import moment from "moment";
let impresoras = null;
export const genera_precuenta_v2 = async (
  cabecera,
  items,
  tama,
  detalle,
  responsable,
  impresora
) => {
  impresoras = impresora;
  switch (tama) {
    case "80":
      var resp = await impresion80(cabecera, items, detalle, responsable);
      break;
    case "58":
      var resp = await impresion58(cabecera, items, detalle);
      break;
  }
  return resp;
};
async function impresion58(cabecera, arraydatos) {
  var arraycabe = cabecera;
  var linea = parseInt(store.state.configImpresora.msuperior);
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var array = arraydatos;
  var emision = "Hora : " + moment(String(new Date())).format("hh:mm A");
  var guardadocumento = store.state.configImpresora.guardadocumento;
  var lMargin = 1; //left margin in mm
  var rMargin = 1; //right margin in mm
  var pdfInMM = 55; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var pageCenter = pdfInMM / 2;

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM],
  });
  doc.setTextColor(10);
  doc.text(".", 0, linea);
  linea = linea + 4;

  doc.setFontSize(10);
  doc.setFont("Helvetica", "Bold");
  if (arraycabe.delivery) {
    var texto = doc.splitTextToSize("DELIVERY", pdfInMM - lMargin - rMargin);
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  } else {
    var texto = doc.splitTextToSize(
      "MESA N°" + arraycabe.id_mesa,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  }
  linea = linea + 3.5 * texto.length;
  doc.setFontSize(9);
  var texto = doc.splitTextToSize(emision, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");

  linea = linea + 3.5 * texto.length;

  doc.setFont("Helvetica", "");

  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 7;
  doc.text(separacion, pageCenter, linea, "center");

  //-----------------productos-----------------------
  var total = 0;
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    var obs = "";
    if (array[i].observacion != "") {
      obs = "\n" + array[i].observacion;
    }
    total = total + parseFloat(array[i].precio * array[i].cantidad);
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = array[i].nombre + " - S/." + array[i].precio + obs;
    nuevoArray[i][2] = parseFloat(array[i].precio * array[i].cantidad).toFixed(
      2
    );
  }

  doc.autoTable({
    margin: {
      top: linea - 8,
      left: 0,
    },
    styles: {
      fontSize: 8,
      cellPadding: 0.4,
      valign: "middle",
      halign: "center",
    },
    headStyles: {
      lineWidth: 0,
      minCellHeight: 9,
    },
    columnStyles: {
      0: {
        columnWidth: 8,
        halign: "center",
        valign: "middle",
      },
      1: {
        columnWidth: 28,
        halign: "left",
        valign: "middle",
      },
      2: {
        columnWidth: 20,
        halign: "center",
        valign: "middle",
      },
    },
    theme: ["plain"],
    head: [["Cant", "Descripcion", "P.T"]],
    body: nuevoArray,
  });

  let finalY = doc.previousAutoTable.finalY;
  linea = finalY + 3;
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 4;
  doc.setFont("Helvetica", "");
  doc.setFontSize(9);
  doc.text("Total : ", lMargin, linea);
  doc.text(
    "S./" +
      parseFloat(total)
        .toFixed(2)
        .toString(),
    lMargin + 20,
    linea
  );
  linea = linea + 3;
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");

  axios_imp(doc.output("arraybuffer"));
}
async function impresion80(cabecera, arraydatos, detalle, responsable) {
  var arraycabe = cabecera;
  var linea = 5;
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var array = arraydatos;
  var emision = moment(String(new Date())).format("hh:mm A - DD/MM");
  var guardadocumento = store.state.configImpresora.guardadocumento;
  var lMargin = 3.5; //left margin in mm
  var rMargin = 2; //right margin in mm
  var pdfInMM = 75; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var pageCenter = pdfInMM / 2;

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [700, pdfInMM],
  });
  doc.setTextColor(10);
  doc.setFontSize(10);
  doc.setFont("Helvetica", "Bold");
  if (arraycabe.delivery) {
    var texto = doc.splitTextToSize(
      "DELIVERY " + arraycabe.id_mesa,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  } else {
    var texto = doc.splitTextToSize(
      "MESA N°" + arraycabe.id_mesa,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  }
  linea = linea + 3.5 * texto.length;
  doc.setFontSize(9);
  var texto = doc.splitTextToSize(emision, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3.5 * texto.length;

  var texto = doc.splitTextToSize(responsable, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3.5 * texto.length;

  doc.setFont("Helvetica", "");
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 7;
  doc.text(separacion, pageCenter, linea, "center");

  //-----------------productos-----------------------
  if (detalle) {
    var total = 0;
    var nuevoArray = new Array(array.length);
    for (var i = 0; i < array.length; i++) {
      var obs = "";
      if (array[i].observacion != "") {
        obs = "\n" + array[i].observacion;
      }
      if (array[i].operacion != "GRATUITA") {
        total = total + parseFloat(array[i].precio * array[i].cantidad);
      }
      nuevoArray[i] = new Array(4);
      nuevoArray[i][0] = array[i].cantidad;
      nuevoArray[i][1] = array[i].nombre + " - S/." + array[i].precio + obs;
      if (array[i].operacion != "GRATUITA") {
        nuevoArray[i][2] = parseFloat(
          array[i].precio * array[i].cantidad
        ).toFixed(2);
      } else {
        nuevoArray[i][2] = "0.00";
      }
    }

    doc.autoTable({
      margin: {
        top: linea - 8,
        left: 1,
      },
      styles: {
        fontSize: 9,
        cellPadding: 0.4,
        valign: "middle",
        halign: "center",
      },
      headStyles: {
        lineWidth: 0,
        minCellHeight: 9,
      },
      columnStyles: {
        0: {
          columnWidth: 10,
          halign: "center",
        },
        1: {
          columnWidth: 45,
          halign: "left",
        },
        2: {
          columnWidth: 15,
          halign: "left",
        },
      },
      theme: ["plain"],
      head: [["Cant", "Descripcion", "P.T"]],
      body: nuevoArray,
    });
    let finalY = doc.previousAutoTable.finalY;
    linea = finalY + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 4;
    doc.setFont("Helvetica", "");
    doc.setFontSize(9);
    doc.text("Total : ", lMargin, linea);
    doc.text(
      "S./" +
        parseFloat(total)
          .toFixed(2)
          .toString(),
      lMargin + 30,
      linea
    );
    linea = linea + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
  } else {
    var nuevoArray = new Array(array.length);
    for (var i = 0; i < array.length; i++) {
      var obs = "";
      if (array[i].observacion != "" && array[i].observacion != undefined) {
        obs = "\n" + array[i].observacion;
      }
      nuevoArray[i] = new Array(4);
      nuevoArray[i][0] = array[i].cantidad;
      nuevoArray[i][1] = array[i].nombre + obs;
    }
    doc.autoTable({
      margin: {
        top: linea - 8,
        left: 1,
      },
      styles: {
        fontSize: 9,
        cellPadding: 0.4,
        valign: "middle",
        halign: "center",
      },
      headStyles: {
        lineWidth: 0,
        minCellHeight: 9,
      },
      columnStyles: {
        0: {
          columnWidth: 10,
          halign: "center",
        },
        1: {
          columnWidth: 60,
          halign: "left",
        },
      },
      theme: ["plain"],
      head: [["Cant", "Descripcion"]],
      body: nuevoArray,
    });
    let finalY = doc.previousAutoTable.finalY;
    linea = finalY + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 3;
  }
  linea = linea + parseFloat(store.state.configImpresora.minferiorgeneral);
  doc.text(".", 0.5, linea);

  axios_imp(doc.output("arraybuffer"));
}

function axios_imp(pdfBuffer) {
  let data = store.state.serv_imp;
  const impresoraEncontrada = data.impresoras.filter(
    (impresora) => impresora.tipo === impresoras
  );

  const nombresImpresoras = impresoraEncontrada.map(
    (impresora) => impresora.deviceId
  );
  console.log(impresoras, nombresImpresoras);
  if (nombresImpresoras.length > 0) {
    axios
      .post("http://" + data.ip + "/print", pdfBuffer, {
        headers: {
          "Content-Type": "application/pdf",
          printernames: JSON.stringify(nombresImpresoras),
        },
      })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  } else {
    alert(`No se encontró una impresora con el tipo: ${impresoras}`);
  }
}
