<template>
    <div class="pa-3">
        <v-row class="mx-auto mt-n1">
            <v-col cols="6" class="text-center">
                <v-btn block class="mb-2" elevation="15" small color="success"
                    @click="dialogAgregaPedido = !dialogAgregaPedido">NUEVO PEDIDO</v-btn>
            </v-col>
        </v-row>

        <v-simple-table class="elevation-3" fixed-header height="60VH">
            <template v-slot:default>
                <thead>

                    <tr>
                        <th class="text-left">
                            Nombre
                        </th>
                        <th class="text-left">
                            observacion
                        </th>
                        <th class="text-left">
                            modo
                        </th>
                        <th class="text-left">
                            Hora
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in desserts" :key="item.id">
                        <td>{{ item.cliente }}</td>
                        <td>{{ item.observacion }}</td>
                        <td class="red--text">{{ item.modo }}</td>
                        <td>{{ convertir_fecha(item.fecha) }}</td>
                        <td>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-icon color="green" @click="ver_detalle(item)">mdi-eye</v-icon>
                                </v-col>
                            </v-row>

                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="dialogAgregaPedido" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogAgregaPedido = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-select class="pa-3" v-model="modo" :items="arrayModo" menu-props="auto" hide-details label="Modo"
                    dense></v-select>

                <v-textarea autofocus v-model="cliente" auto-grow filled color="deep-purple" label="Cliente" rows="1"
                    dense></v-textarea>

                <v-textarea v-model="observacion" auto-grow filled color="deep-purple" label="Observacion"
                    rows="1"></v-textarea>
                <v-row class="mx-auto text-center">

                </v-row>

                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaPedido()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_detalle" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_detalle = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-2">
                <v-row dense class="text-center mt-1">
                    <v-col cols="6" sm="4">
                        <v-card @click.prevent="editar_delivery()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/convertir.png"></v-img>
                                <h5 block class="text-center">Editar</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-card @click.prevent="comanda()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/disc.png"></v-img>
                                <h5 block class="text-center">Comanda</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-card @click.prevent="pre_cuenta(true)">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/pago.png"></v-img>
                                <h5 block class="text-center">Precuenta</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-card @click.prevent="eliminar()" :disabled="!$store.state.permisos.limpia_comanda">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/anular.png"></v-img>
                                <h5 block class="text-center">Limpiar</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card class="pa-2 mt-2">
                    <v-simple-table fixed-header height="30vh" dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Producto
                                    </th>
                                    <th class="text-left">
                                        Obs
                                    </th>
                                    <th class="text-left">
                                        Precio
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in array_comanda" :key="item.id">
                                    <td style="font-size:80%;">
                                        <strong class="red--text"> {{
                                            item.cantidad }} x</strong> {{ item.nombre }}
                                    </td>
                                    <td style="font-size:80%;">{{ item.observacion }}
                                    </td>
                                    <td style="font-size:80;">{{ (item.precio * item.cantidad).toFixed(2) }}
                                    </td>

                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import {
    nuevoDelivery,
    alldelivery,
    eliminaDelivery,
    eliminaComandaDelivery,
    buscaComandaDelivery
} from '../../db'
import axios from "axios"
import {
    pdfprecuenta,
    genera_precuenta,
    pc_print
} from '../../pdf_precuentas'
import {
    genera_precuenta_v2
} from '../../pdf_precuentas_v2'
import moment from 'moment'
import store from '@/store/index'
export default {

    data() {
        return {
            desserts: [],
            arrayModo: ['RECOJO', 'ENVIO'],
            modo: 'RECOJO',
            dialogAgregaPedido: false,
            cliente: '',
            observacion: '',
            idpedido: '',
            clientepedido: '',
            item_selecto: '',
            dial_detalle: false,
            array_comanda: [],
            permite_editar: false,
        }
    },
    created() {
        // this.graba_descartable("sd")
    },
    mounted() {
        alldelivery().on("value", this.onDataChange);
    },
    beforeDestroy() {
        alldelivery().off("value", this.onDataChange);
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                let key = item.key
                data.id = key
                array.push(data);

            });
            this.desserts = array
        },
        convertir_fecha(data) {
            var fecha = moment.unix(data).format('hh:mm A')
            return fecha
        },
        async ver_detalle(val) {
            this.permite_editar = false
            if (store.state.configuracion.edita_comandas && !store.state.permisos.moduloempresa) {
                this.permite_editar = false
            } else {
                this.permite_editar = true
            }
            store.commit("dialogoprogress")
            this.item_selecto = val
            var snapshot = await buscaComandaDelivery(val.id).once("value")
            this.array_comanda = snapshot.val()
            store.commit("dialogoprogress")
            this.dial_detalle = true
        },
        async grabaPedido() {
            if (this.cliente == '') {
                alert('Complete Datos Cliente')
                return
            }
            var array = {
                modo: this.modo,
                cliente: this.cliente,
                observacion: this.observacion,
                fecha: moment().unix()
            }
            var result = await nuevoDelivery(array)
            this.dialogAgregaPedido = false
            this.$router.push({
                path: "/gestionDetalleDelivery/" + result.key + "/" + array.cliente
            })
        },
        async eliminar() {
            if (confirm('seguro que desea anular?')) {
                store.commit("dialogoprogress")
                await eliminaDelivery(this.item_selecto.id)
                await eliminaComandaDelivery(this.item_selecto.id)
                if (store.state.configuracion.stock_comandas) {
                    var arrays = {
                        metodo: 'SUMA',
                        array: this.array_comanda
                    }
                    this.api_rest(arrays, 'modifica_stock_array')
                }
                this.dial_detalle = false
                store.commit("dialogoprogress")
            }
        },

        api_rest(data, metodo) {

            var a = axios({
                method: 'POST',
                url: 'https://us-central1-mitienda-f5ef8.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/mitienda-f5ef8/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                console.log(response.data)
                return response
            })
            return a
        },
        editar_delivery() {
            this.$router.push({
                path: "/gestionDetalleDelivery/" + this.item_selecto.id + "/" + this.item_selecto.cliente
            })
        },
        async comanda() {
            var lista_pendiente = this.array_comanda
            var array = {
                delivery: true,
                id_mesa: this.item_selecto.id,
            }
            var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
            var array_cocina = lista_pendiente.filter(item => item.cocina == true)
            var array_barra = lista_pendiente.filter(item => item.barra == true)
            var array_cocina2 = lista_pendiente.filter(item => item.cocina2 == true)
            var array_barra2 = lista_pendiente.filter(item => item.barra2 == true)
            if (store.state.configImpresora.impresoracocina) {
                await this.envio_impresora(array_cocina, array_barra, array_cocina2, array_barra2)
                return
            }
            const procesarPreCuenta = (area, array) => {
                if (array.length > 0) {
                    console.log(area)
                    genera_precuenta_v2(
                        { delivery: true, id_mesa: this.item_selecto.id },
                        array,
                        '80',
                        false,
                        responsable,
                        area
                    );
                }
            };
            if (store.state.configImpresora.impresora_auto) {
                procesarPreCuenta("Cocina1", array_cocina);
                procesarPreCuenta("Barra1", array_barra);
                procesarPreCuenta("Cocina2", array_cocina2);
                procesarPreCuenta("Barra2", array_barra2);
            }
            else {
                this.pre_cuenta(false)
            }
        },
        async envio_impresora(cocina, barra, cocina2, barra2) {
            if (cocina == '' && barra == '' && cocina2 == '' && barra2 == '') {
                return
            }
            var fecha = moment(String(new Date())).format('YYYY-MM-DD hh:mm')
            var responsable = store.state.permisos.correo
            if (cocina != '') {
                for (var i = 0; i < cocina.length; i++) {

                    cocina[i].observacion = cocina[i].observacion ? cocina[i].observacion.toString() : ''
                }
            }
            if (barra != '') {
                for (var i = 0; i < barra.length; i++) {
                    barra[i].observacion = barra[i].observacion ? barra[i].observacion.toString() : ''
                }
            }
            if (cocina2 != '') {
                for (var i = 0; i < cocina2.length; i++) {
                    cocina2[i].observacion = cocina2[i].observacion ? cocina2[i].observacion.toString() : ''
                }
            }
            if (barra2 != '') {
                for (var i = 0; i < barra2.length; i++) {
                    barra2[i].observacion = barra2[i].observacion ? barra2[i].observacion.toString() : ''
                }
            }
            var a = await pc_print(this.item_selecto.cliente, responsable.substring(0, responsable.indexOf('@')),
                fecha, 0, cocina, barra, cocina2, barra2)
            console.log(a)
            return true
        },
        pre_cuenta(val) {
            console.log(this.item_selecto)
            var array = {
                delivery: true,
                id_mesa: this.item_selecto.cliente,
            }
            genera_precuenta(array, this.array_comanda, '80', val)

        },
    }

}
</script>
