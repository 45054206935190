<template>
    <v-dialog v-model="dial" max-width="550" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-radio-group class="" v-model="medida_comprobante" row dense>
                    <v-radio label="A4" value="A4"></v-radio>
                    <v-radio label="80mm" value="80"></v-radio>
                    <v-radio label="58mm" value="58"></v-radio>
                </v-radio-group>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <div class="text--center">
                <v-radio-group class="mt-n1" v-model="medida_comprobante" row dense>
                    <v-radio label="A4" value="A4"></v-radio>
                    <v-radio label="80mm" value="80"></v-radio>
                    <v-radio label="58mm" value="58"></v-radio>
                </v-radio-group>
            </div>
            <v-row dense>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="verPDF('imprime')">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/disc.png"></v-img>
                            <h5 block class="text-center pa-1">IMPRIME</h5>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="(envia_what = true)">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/what.png"></v-img>
                            <h5 block class="text-center pa-1">WHATSAPP</h5>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="(envia_correo = true)">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/mail.png"></v-img>
                            <h5 block class="text-center pa-1">CORREO</h5>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="6" xs="6" sm="3">
                    <v-card @click.prevent="verPDF('descarga')">
                        <v-container>
                            <v-img class="mx-auto" height="35" width="35" src="/pdf.png"></v-img>
                            <h5 block class="text-center pa-1">DESCARGA</h5>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="envia_what" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="(envia_what = !envia_what)">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-5">
                <h4 class="text-center">Completa los Datos</h4>
                <h5 class="text-center">Este proceso generara un link para envio.</h5>
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-text-field append-icon="mdi-send-check" @click:append="envia_link_('w')" prepend-icon="+51"
                            dense outlined v-model="numero" type="number" label="Envio Whatsapp"
                            @keyup.enter="envia_link_('w')"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="envia_correo" max-width="450">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="(envia_correo = !envia_correo)">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-5">
                <h4 class="text-center">Completa los Datos</h4>
                <v-row dense class="mt-1">
                    <v-col cols="12">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field autofocus :rules="emailRules" dense outlined v-model="correo"
                                label="Envio Correo"></v-text-field>
                            <v-btn :disabled="!valid" color="info" class="mr-4" @click="envia_link_('c')">
                                ENVIAR CORREO
                            </v-btn>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog persistent v-model="progress" max-width="250">
            <v-card class="pa-12">
                <v-progress-linear indeterminate color="blue-grey" height="25">
                </v-progress-linear>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import {
    consultaDetalle,
    edita_campo_Cliente
} from '../../db'
import axios from "axios"
import {
    pdfGenera
} from '../../pdf_comprobantes'
import store from '@/store/index'
import moment from 'moment'
export default {
    name: 'caja',

    props: {
        data: [],
    },
    data() {
        return {
            dial: false,
            valid: true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            progress: false,
            envia_what: false,
            envia_correo: false,
            correo: '',
            numero: '',
            seleccionado: '',
            datos_cliente: [],
            medida_comprobante: store.state.configImpresora.tamano
        }
    },
    created() {
        this.seleccionado = this.data
        if (this.seleccionado.dni != "00000000") {
            var cliente = store.state.clientes.find(id => String(id.documento) === String(this.seleccionado.dni))

            this.datos_cliente = cliente
            if (Boolean(cliente)) {
                this.numero = cliente.telefono
                this.correo = cliente.correo
            } else {
                this.datos_cliente = {
                    id: '00000000'
                }
            }
        } else {
            this.datos_cliente = {
                id: '00000000'
            }
        }
        this.dial = true
    },
    methods: {
        envia_link_(modo) {
            var item = this.seleccionado
            switch (modo) {
                case 'w':
                    if (this.numero != '' && String(this.numero).length == 9) {
                        console.log(this.datos_cliente.id, 'telefono', this.numero)
                        if (this.seleccionado.dni != "00000000") {
                            edita_campo_Cliente(this.datos_cliente.id, 'telefono', this.numero)
                        }
                        var message = 'Puede ver su comprobante en el siguiente link \n' +
                            'https://domotica-peru.com/comprobantes_clientes/' + store.state.baseDatos.bd + '/' + store.state.baseDatos.ruc + '/' + item.cod_comprobante + '/' + item.serie + '/' + item.correlativoDocEmitido + '/' + item.dni
                        if (store.state.esmovil) {
                            var url = "whatsapp://send?text=" + encodeURIComponent(message) + "&phone=" + encodeURIComponent('+51' + this.numero)
                        } else {
                            var url = "https://web.whatsapp.com/send?text=" + encodeURIComponent(message) + "&phone=" + encodeURIComponent('+51' + this.numero)
                        }
                        window.open(url);
                    } else {
                        store.commit('dialogosnackbar', 'Numero no es correcto!')
                    }
                    this.cierra()
                    break;
                case 'c':

                    if (this.datos_cliente != '') {
                        edita_campo_Cliente(this.datos_cliente.id, 'correo', this.correo)
                    }
                    this.progress = true
                    axios({
                        method: 'post',
                        url: 'https://us-central1-factura-peru.cloudfunctions.net/mailer',
                        headers: {},
                        data: {
                            "to": this.correo,
                            "subject": "Comprobante " + item.serie + '-' + item.correlativoDocEmitido,
                            "message": "Hola, tenemos un comprobante para ti",
                            "url_comprobante": 'https://factura-peru.web.app/comprobantes_clientes/' + store.state.baseDatos.ruc + '/' + item.cod_comprobante + '/' + item.serie + '/' + item.correlativoDocEmitido + '/' + item.dni,
                            "ruc_emisor": store.state.baseDatos.ruc,
                            "razon_social": store.state.baseDatos.name,
                            "fecha": moment.unix(item.fecha).format('YYYY-MM-DD'),
                            "correlativo": item.serie + '-' + item.correlativoDocEmitido
                        }
                    }).then(response =>
                        this.termina(response)
                    )
                    break;

            }
        },
        termina(a) {
            this.progress = false
            this.cierra()
        },
        async verPDF(modo) {
            var item = this.seleccionado
            this.progress = true
            var arraydatos = []
            let snapshot = await consultaDetalle(item.numeracion).once("value")
            arraydatos = snapshot.val()
            if (modo == 'descarga') {
                pdfGenera(arraydatos, item, this.medida_comprobante, 'descarga')
            }
            if (modo == 'imprime') {
                pdfGenera(arraydatos, item, this.medida_comprobante, 'abre')
            }
            //this.imprime(resp)
            this.progress = false
            this.cierra()

        },
        imprime(datas) {
            // pc_print(data)
            var nom_impresora = 'Microsoft Print to PDF'
            console.log(datas)
            const form = new FormData()
            //var nom_impresora = 'ZDesigner GT800 (EPL)' 

            // fileInputElement
            let file = new File([datas], "img.pdf", { type: "application/pdf", lastModified: new Date().getTime() });
            let container = new DataTransfer();
            container.items.add(file);
            console.log(container.files)
            document.getElementById("id_file").files = container.files;
            //document.getElementById("id_file").value = data
            document.getElementById("id_impresora").value = nom_impresora
            var a = window.open('', 'TheWindow', 'left=5000,top=5000,width=0,height=0')
            document.getElementById('id_form').submit()
            /* setTimeout(function () {
                 a.close();
                 //  resolve(true)
             }, 1000);* /
 
             /*     const ff = document.getElementById('id_form')
                  document.getElementById("id_file").value = data
                  document.getElementById("id_impresora").value = nom_impresora
                  ff.addEventListener('submit', function (event) {
                      event.preventDefault()
                      const fi = document.getElementById('id_file')
                      const uf = fi.files[0]
                      const bd = new Blob([uf], { type: uf.type })
                      const bu = URL.createObjectURL(bd)
                      window.open(bu, '_blank')
                      URL.revokeObjectURL(bu)
                  })*/

        },
        cierra() {

            this.$emit('cierra', false)
        }
    },

}
</script>
