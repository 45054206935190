<template>
    <div class="mb-6 mt-2 pa-3">
        <v-row>
            <v-col cols="6">

                <v-row class="mx-auto mt-n7">
                    <v-col cols="4" class="text-center">
                        <v-btn block elevation="10" x-small rounded color="info"
                            @click="$store.commit('dialogoproductos')">
                            Catalogo (F1)
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn block elevation="10" x-small rounded color="error" @click="dialogAgrega = !dialogAgrega">
                            Agregar
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center" v-if="$store.state.baseDatos.restaurante == true">
                        <v-btn block elevation="10" x-small rounded color="warning"
                            @click="buscaMesa(), separacuentas = true">
                            Divide Cuenta
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card class="mt-3 pa-3" elevation="6">
                    <v-text-field v-if="$store.state.configuracion.codbarras" autofocus dense ref="cod_barra"
                        v-model="codigo" label="Codigo de Barra" append-icon="mdi-barcode-scan"
                        @click:append="busca_codigo_barra()" @keyup.enter="busca_codigo_barra()"></v-text-field>
                    <v-autocomplete autofocus class="mt-n3" label="Busca Producto" auto-select-first v-model="busca_p"
                        :items="array_productos" @keyup.enter="selecciona()"></v-autocomplete>
                    <v-simple-table fixed-header height="55vh" dense>

                        <template v-slot:default>

                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Cant.
                                    </th>
                                    <th class="text-left">
                                        Descripcion
                                    </th>
                                    <th class="text-left">
                                        Medida
                                    </th>
                                    <th class="text-left">
                                        Precio
                                    </th>
                                    <th v-if="sumaDescuentos() != 0" class="text-left">
                                        Desc.
                                    </th>
                                    <th class="text-left">
                                        Total
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr v-for="item in listaproductos" :key="item.id"
                                    @click.prevent="editaProducto(item.id)">
                                    <td>{{ item.cantidad }}</td>
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ item.medida }}</td>
                                    <td>S/.{{ item.precioedita }}</td>
                                    <td class="red--text" v-if="sumaDescuentos() != 0">S/.{{
                                        redondear(item.preciodescuento)
                                    }}</td>
                                    <td>S/.{{ redondear((item.precioedita * item.cantidad) - item.preciodescuento) }}
                                    </td>
                                </tr>
                            </tbody>
                            <v-row v-if="listaproductos == '' && $store.state.baseDatos.restaurante == true"
                                class="mt-8">
                                <v-icon color="red" @click="buscaMesa(), esdelivery = false, separacuentas = false"
                                    class="mx-auto text--center">mdi-table-chair</v-icon>
                            </v-row>
                            <v-row v-if="listaproductos == '' && $store.state.baseDatos.restaurante == true"
                                class="mt-8">
                                <v-icon color="red" @click="buscaDelivery(), esdelivery = true"
                                    class="mx-auto text--center">mdi-moped-electric-outline</v-icon>
                            </v-row>
                        </template>

                    </v-simple-table>

                </v-card>

            </v-col>
            <v-col cols="6">
                <v-card class="mt-n5 pa-2">
                    <v-row class="mt-n4 mb-n9">
                        <v-col cols="8">
                            <h4>Datos del Cliente : </h4>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox dense v-model="guarda_cliente" label="Guarda Cliente"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n4">
                        <v-col cols="4">
                            <v-text-field type="date" outlined dense v-model="date" label="Emision"></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select outlined dense v-model="documento" :items="documentos" menu-props="auto"
                                hide-details label="Tipo Doc"></v-select>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field outlined type="number" dense v-model="numero" label="Numero"
                                append-icon="mdi-magnify" @click:append="BuscarDocumento()"
                                @keyup.enter="BuscarDocumento()"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n9">
                        <v-col cols="12">
                            <v-text-field shaped outlined small dense v-model="nombreCompleto"
                                label="Nombres Completos"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-n9">
                            <v-textarea dense outlined v-model="direccion" auto-grow filled label="Direccion"
                                rows="1"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n9">
                        <v-col cols="10">
                            <v-text-field outlined dense v-model="correo" label="Correo Electronico"></v-text-field>

                        </v-col>
                        <v-col cols="2">
                            <v-checkbox dense v-model="envia_correo" label="Envia"></v-checkbox>
                        </v-col>

                        <v-col cols="12" v-if="$store.state.configuracion.comisiones" class="mt-n9">
                            <v-text-field outlined dense append-icon="mdi-magnify" @click:append="BuscarEmpleado()"
                                @keyup.enter="BuscarEmpleado()" v-model="empleado" label="Empleado"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="$store.state.configuracion.obscomprobante" class="mt-n9">
                            <v-text-field outlined dense v-model="observacion" label="Observacion"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="$store.state.configImpresora.refcliente" class="mt-n9">
                            <v-text-field outlined dense v-model="refcliente" label="Referencia"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="$store.state.configImpresora.telfcliente" class="mt-n9">
                            <v-text-field outlined type="number" dense v-model="telfcliente"
                                label="Telefono"></v-text-field>
                        </v-col>

                    </v-row>
                </v-card>
                <v-card class="mt-3 pa-2">
                    <v-row>
                        <v-col cols="12" md="4" sm="4" xs="12">
                            <v-list-item-content class="ml-3">
                                <v-list-item-subtitle v-if="sumaDescuentos() != 0">
                                    <h3>SubTotal: S/.{{ sumaTotal() }}</h3>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="sumaDescuentos() != 0">
                                    <h4 class="red--text">Descuentos: S/.{{ sumaDescuentos() }}</h4>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>

                                    <h2>Total: S/.{{ redondear(sumaTotal() - sumaDescuentos()) }}</h2>

                                </v-list-item-subtitle>

                            </v-list-item-content>

                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="mt-1 pa-2">
                    <v-radio-group v-model="modo_pago" row class="mt-n1 mb-n5" dense>
                        <v-radio v-for="n in $store.state.modopagos" :key="n" :label="n" :value="n"></v-radio>
                    </v-radio-group>
                </v-card>
                <v-card class="mt-1 pa-2">
                    <v-row class="mt-n4">
                        <v-col cols="4">
                            <v-btn :disabled="listaproductos == ''" color="primary" block small
                                @click="genera_comprobante(1)">
                                NOTA DE VENTA (F2)
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn :disabled="listaproductos == ''" color="success" block small
                                @click="genera_comprobante(2)">
                                BOLETA (F3)
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn :disabled="listaproductos == '' || documento != 'RUC'" color="accent" block small
                                @click="genera_comprobante(3)">
                                FACTURA
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>

                <v-btn v-if="$store.state.configuracion.flujocaja == true" @click="irFlujo()" block elevation="15" small
                    rounded color="yellow" class="mt-2">FLUJO DE CAJA</v-btn>
                <v-card class="mt-2 pa-2" v-if="false">
                    <h4 class="mb-3">Datos Pago : </h4>
                    <v-card class="mb-1">
                        <v-simple-table fixed-header height="100px" dense>
                            <thead>

                                <tr>
                                    <th class="text-left">
                                        modo
                                    </th>
                                    <th class="text-left">
                                        monto
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in modopagos" :key="item.modo">
                                    <td colspan="2">
                                        <v-row class="mt-1" dense>
                                            <v-col cols="6">
                                                <v-select v-model="item.modo" :items="$store.state.modopagos"
                                                    dense></v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field type="number" v-model="item.monto" dense
                                                    @keyup.enter="e1 = 2"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </tbody>

                            <v-row class="mt-1 text-center">
                                <v-col cols="6">
                                    <v-icon @click="modopagoDialog()" color="green">mdi-plus</v-icon>
                                </v-col>
                                <v-col cols="6">
                                    <v-icon @click="eliminamodopago()" color="red">mdi-minus</v-icon>
                                </v-col>
                            </v-row>

                        </v-simple-table>

                    </v-card>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog v-model="dialogo_separa" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_separa = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">Seleccione Solo consumo a Pagar</h4>
                <v-simple-table dark fixed-header height="350px" dense>
                    <template v-slot:default>

                        <thead>
                            <tr>

                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Consumido
                                </th>
                                <th class="text-left">
                                    A cobrar
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in array_separa" :key="item.uuid">

                                <td>{{ item.nombre }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>
                                    <v-icon large @click="suma_separa(item)" color="green">mdi-plus</v-icon>
                                    {{ item.cantidadedita }}
                                    <v-icon large @click="resta_separa(item)" color="red">mdi-minus</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
                <v-card-title primary-title>

                </v-card-title>
                <v-btn color="success" block @click="graba_separacion()">Continuar</v-btn>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAgrega" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogAgrega = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mb-n12">
                    <v-col cols="6" sm="6" md="6">
                        <v-select :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="tipooperacion"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mx-auto mt-4">
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" v-model="cantidadSinCodigo" label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" v-model="precioSinCodigo" label="Precio"></v-text-field>
                    </v-col>

                </v-row>
                <v-select class="pa-3" v-model="medidasincodigo" :items="$store.state.medidas" menu-props="auto"
                    hide-details label="Medida"></v-select>

                <v-text-field @keyup.enter="agregaSinCatalogo()" type="text" class="pa-3" v-model="nombreSincodigo"
                    label="Descripcion"></v-text-field>

                <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="agregaSinCatalogo()">
                        Agregar
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialogoProducto" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoProducto = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto mt-4 text-center" dense>

                    <v-col cols="4" xs="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>

                    <v-col cols="4" xs="4">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" outlined v-model="cantidadEdita"
                            label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="4">
                        <v-icon @click="restaa()" color="red">mdi-minus</v-icon>
                    </v-col>

                </v-row>
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12" class="mb-n4 mt-n4">
                        <v-text-field dense @keyup.enter="grabaEdita()" class="pa-3" v-model="nombreEdita"
                            label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" class="pa-3" v-model="precioedita"
                            label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" class="pa-3"
                            v-model="preciodescuento" label="Descuento"></v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions class="mt-n6">

                    <v-btn color="red darken-1" text @click="eliminaedita()">
                        Elimina
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoMesas" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoMesas = !dialogoMesas">mdi-close</v-icon>
                </v-system-bar>
            </div>

            <v-card>

                <v-simple-table height="500px">
                    <template v-slot:default>
                        <thead>

                            <tr>
                                <th class="text-left">
                                    Numero Mesa
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in mesasActivas" :key="item.numeromesa">
                                <td>{{ item.cliente }}</td>
                                <td>
                                    <v-icon @click="copiaDataMesa(item.numeromesa)" color="red">mdi-magnify</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogoempleado" max-width="490">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoempleado = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="buscar" append-icon="mdi-magnify" label="Search" single-line hide-details
                        dense></v-text-field>
                </v-card-title>

                <v-simple-table fixed-header height="400px" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    NOMBRE
                                </th>
                                <th class="text-left">
                                    TELEFONO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltradaempleados" :key="item.id" @click="agregaEmpleado(item)">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.telefono }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_vuelto" max-width="800">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_vuelto = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mt-n4">
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10">
                            <v-img class="mx-auto" src="/billete_10.png">
                                <h1 class="text-center red--text">2</h1>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10">
                            <v-img class="mx-auto" src="/billete_20.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10">
                            <v-img class="mx-auto" src="/billete_50.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10">
                            <v-img class="mx-auto" src="/billete_100.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10">
                            <v-img class="mx-auto" src="/billete_200.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                </v-row>
                <v-row class="mt-n4">
                    <v-col cols="2">
                        <v-card elevation="10" width="7vh">
                            <v-img class="mx-auto" src="/moneda_0.1.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10" width="7vh">
                            <v-img class="mx-auto" src="/moneda_0.2.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10" width="7vh">
                            <v-img class="mx-auto" src="/moneda_0.5.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10" width="7vh">
                            <v-img class="mx-auto" src="/moneda_1.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10" width="7vh">
                            <v-img class="mx-auto" src="/moneda_2.png"></v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card elevation="10" width="7vh">
                            <v-img class="mx-auto" src="/moneda_5.png"></v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialo_cantidad" max-width="200px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialo_cantidad = !dialo_cantidad">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card>

                <v-card-title>
                    <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD"
                        @focus="$event.target.select()" @keyup.enter="agrega_con_cantidad()"></v-text-field>
                </v-card-title>
                <v-btn class="mt-n6" color="red" @click="agrega_con_cantidad()" block>OK</v-btn>
            </v-card>
        </v-dialog>
        <cobrar />
        <cateogrias @array="agregaCatalogo($event)" />
    </div>
</template>

<script>
import {
    nuevaComanda,
    obtenContador,
    edita_Comanda,
    elimina_producto_comanda,
    nuevoflujo,
    buscaCliente,
    allClientes,
    buscaComanda,
    allComandas,
    alldelivery,
    sumaContador,
    grabarStock,
    eliminaComanda,
    eliminaComandaDelivery,
    eliminaDelivery,
    grabaEstadomesa,
    buscaComandaDelivery,
    grabaObservacionMesa,
    allEmpleados
} from '../db'
import {
    guardaComprobante
} from '../servidorsunat'
import {
    cobrar_js
} from '../genera_comprobante'
import moment from 'moment'
import store from '@/store/index'
import axios from "axios"
import cateogrias from '@/components/dialogos/dialogocatalogo'
import cobrar from '@/components/dialogos/dialogo_cobrar'
export default {
    name: 'caja',

    components: {
        cateogrias,
        cobrar
    },

    data() {
        return {
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            busca_p: '',
            array_productos: [],
            dialogAgrega: false,
            dialogoProducto: false,
            dialogCobra: false,
            dialogoMesas: false,
            dialogoempleado: false,
            dial_vuelto: false,
            codigo: '',
            listaproductos: [],
            mesasActivas: [],
            dataMesa: [],
            idmesa: 'xx',
            buscar: '',
            codigoedita: '',
            cantidadEdita: '',
            precioedita: '',
            nombreEdita: '',
            preciodescuento: '',
            cantidadSinCodigo: 1,
            precioSinCodigo: '',
            medidasincodigo: 'UNIDAD',
            nombreSincodigo: '',
            tipocomprobante: 'T',
            correlativo: '',
            modo_pago: 'EFECTIVO',
            documentos: [
                'DNI',
                'RUC',
                'Pasaporte',
                'Carnet de Extranjeria'
            ],
            documento: 'DNI',
            ordenboleta: '',
            ordenticket: '',
            ordenfactura: '',
            ordenproformas: '',
            numero: '',
            nombreCompleto: '',
            direccion: '',
            esdelivery: false,
            ordenflujo: '',
            modopagos: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA'
            ],
            tipooperacion: 'GRAVADA',
            tipoproducto: "BIEN",
            separacuentas: false,
            dialogocliente: false,
            clienteslista: [],
            telefono: '',
            refcliente: '',
            telfcliente: '',
            e1: 1,
            calculavuelto: 0,
            paravuelto: '',
            focusvuelto: true,
            dialogocredito: false,
            cuotasCredito: [],
            observacion: '',
            dialogoempleado: false,
            empleadoslista: [],
            empleado: '',
            dniempleado: '',
            dialogo_separa: false,
            array_separa: [],
            array_comanda: [],
            mesero: '',
            envia_correo: false,
            correo: '',
            guarda_cliente: true,
            intervalo: true,
            dialo_cantidad: false,
            cantidad: 1,
            producto_selecto: []
        }
    },
    mounted() {
        let self = this;
        window.addEventListener('keydown', function (event) {

            if (event.keyCode === 112) { // F1
                event.preventDefault();
                store.commit('dialogoproductos');
            }
            if (event.keyCode === 113 && self.intervalo) { // F2
                event.preventDefault();
                if (self.listaproductos != '') {
                    self.genera_comprobante(1)
                    self.espera()
                }
            }
            if (event.keyCode === 114 && self.intervalo) { // F3
                event.preventDefault();
                if (self.listaproductos != '') {
                    self.genera_comprobante(2)
                    self.espera()
                }
            }
            if (event.keyCode === 115) { // F4
                event.preventDefault();
                self.dial_vuelto = true
            }
        });

    },
    created() {
        this.inicio()
    },
    methods: {
        espera() {
            this.intervalo = false
            setTimeout(() => this.intervalo = true, 1500);
        },
        inicio() {
            var array = store.state.productos
            for (var i = 0; i < array.length; i++) {
                this.array_productos[this.array_productos.length] = array[i].id + '/' + array[i].categoria + ' / ' + array[i].nombre + '- S/.' + array[i].precio + ' x ' + array[i].medida
            }
        },
        selecciona() {
            var producto = this.busca_p.split('/')[0].trim()
            var data = store.state.productos.filter(item => item.id == producto)[0]
            if (data != undefined) {
                this.producto_selecto = data
                this.cantidad = 1
                this.dialo_cantidad = true
            }
        },
        agrega_con_cantidad() {
            this.dialo_cantidad = false
            this.producto_selecto.cantidad = this.cantidad
            this.busca_p = ''
            this.agregaCatalogo(this.producto_selecto)
        },
        busca_codigo_barra() {
            var producto = this.codigo.trim()
            var data = store.state.productos.filter(item => item.codbarra == producto)[0]
            data.cantidad = 1
            this.agregaCatalogo(data)
            this.codigo = ''
        },
        suma_separa(item) {
            for (var i = 0; i < this.array_separa.length; i++) {
                if (this.array_separa[i].uuid == item.uuid) {
                    if (this.array_separa[i].cantidadedita < this.array_separa[i].cantidad) {
                        this.array_separa[i].cantidadedita++
                    }
                }
            }
        },
        resta_separa(item) {
            for (var i = 0; i < this.array_separa.length; i++) {
                if (this.array_separa[i].uuid == item.uuid) {
                    if (this.array_separa[i].cantidadedita > 0) {
                        this.array_separa[i].cantidadedita--
                    }
                }
            }
        },
        graba_separacion() {
            this.listaproductos = []
            for (var i = 0; i < this.array_separa.length; i++) {
                var data = this.array_separa[i]
                if (data.cantidadedita != 0) {
                    this.listaproductos.push({
                        id: data.id,
                        codbarra: data.id,
                        cantidad: data.cantidadedita,
                        nombre: data.nombre,
                        medida: data.medida,
                        categoria: data.categoria,
                        precio: data.precio,
                        stock: data.stock,
                        precioedita: data.precio,
                        preciodescuento: data.preciodescuento,
                        costo: 0,
                        cocina: data.cocina,
                        tipoproducto: data.tipoproducto,
                        operacion: data.operacion,
                        icbper: 'false',
                        controstock: data.controstock,
                        cargoxconsumo: data.cargoxconsumo
                    })
                }
            }
            this.dialogo_separa = false
        },
        elimina_separa() {
            var array = []
            for (var e = 0; e < this.array_separa.length; e++) {
                if (this.array_separa[e].cantidadedita < this.array_separa[e].cantidad && this.array_separa[e].cantidadedita != 0) {
                    var sum = parseFloat(this.array_separa[e].cantidad) - parseFloat(this.array_separa[e].cantidadedita)
                    this.array_comanda[e].cantidad = sum
                    array.push(this.array_comanda[e])
                }
                if (this.array_separa[e].cantidadedita == 0) {
                    array.push(this.array_comanda[e])
                }
            }
            if (array == '') {
                grabaEstadomesa(this.idmesa, "#46FF00")
                grabaObservacionMesa(this.idmesa, "")
                eliminaComanda(this.idmesa)
            } else {
                nuevaComanda(this.idmesa, array).then(a => {
                    this.separacuentas = false
                })
            }
        },
        agregaCatalogo(value) {
            this.dialogoCatalogo = false
            if (value.stock <= 0 && store.state.configuracion.inventario) {
                store.commit('dialogosnackbar', 'NO TIENE STOCK DISPONIBLE')
            } else {
                if (this.listaproductos.indexOf(this.buscarArray(this.listaproductos, value.id)) == -1) {
                    this.listaproductos.push({
                        id: value.id,
                        codbarra: value.codbarra,
                        cantidad: value.cantidad,
                        nombre: value.nombre,
                        categoria: value.categoria,
                        medida: value.medida,
                        precio: value.precio,
                        stock: value.stock,
                        precioedita: value.precio,
                        preciodescuento: 0,
                        costo: value.costo,
                        cocina: value.cocina,
                        tipoproducto: value.tipoproducto,
                        operacion: value.operacion,
                        icbper: value.icbper,
                        controstock: value.controstock,
                        cargoxconsumo: value.cargoxconsumo
                    })
                } else {
                    this.sumaproductos(value)
                }
            }
        },
        agregaSinCatalogo() {
            if (this.nombreSincodigo == '' || this.medidasincodigo == '' ||
                this.precioSinCodigo == '' || this.cantidadSinCodigo == '') {
                store.commit('dialogosnackbar', 'REVISE PRODUCTO')
            } else {
                this.listaproductos.push({
                    id: this.create_UUID().substring(29),
                    codbarra: this.create_UUID().substring(29),
                    cantidad: this.cantidadSinCodigo.toString().trim(),
                    nombre: this.nombreSincodigo,
                    medida: this.medidasincodigo,
                    categoria: 'varios',
                    precio: this.redondear(this.precioSinCodigo.toString().trim()),
                    stock: 9090909,
                    precioedita: this.redondear(this.precioSinCodigo.toString().trim()),
                    preciodescuento: 0,
                    costo: 0,
                    cocina: false,
                    tipoproducto: this.tipoproducto,
                    operacion: this.tipooperacion,
                    icbper: 'false',
                    controstock: false,
                    cargoxconsumo: false
                })
                this.dialogAgrega = false
                this.nombreSincodigo = ''
                this.medidasincodigo = ''
                this.precioSinCodigo = ''
            }
        },
        copiaDataMesa(mesa) {
            this.array_separa = []
            store.commit("dialogoprogress")
            if (this.esdelivery) {
                this.copiadelivery(mesa)
            } else {
                this.idmesa = mesa
                buscaComanda(mesa).once("value").then((snapshot) => {
                    snapshot.forEach((item) => {
                        this.mesero = item.val().usuario
                        if (!this.separacuentas) {
                            this.listaproductos.push({
                                id: item.val().id,
                                codbarra: item.val().id,
                                cantidad: item.val().cantidad,
                                nombre: item.val().nombre,
                                medida: item.val().medida,
                                categoria: item.val().categoria,
                                precio: item.val().precio,
                                stock: item.val().stock,
                                precioedita: item.val().precio,
                                preciodescuento: item.val().preciodescuento,
                                costo: 0,
                                cocina: item.val().cocina,
                                tipoproducto: item.val().tipoproducto,
                                operacion: item.val().operacion,
                                icbper: 'false',
                                controstock: item.val().controstock,
                                cargoxconsumo: item.val().cargoxconsumo,
                                uuid: item.val().uuid
                            })
                        } else {
                            this.array_comanda.push(item.val())
                            this.array_separa.push({
                                id: item.val().id,
                                codbarra: item.val().id,
                                cantidad: item.val().cantidad,
                                cantidadedita: item.val().cantidad,
                                nombre: item.val().nombre,
                                medida: item.val().medida,
                                categoria: item.val().categoria,
                                precio: item.val().precio,
                                stock: item.val().stock,
                                precioedita: item.val().precio,
                                preciodescuento: item.val().preciodescuento,
                                costo: 0,
                                cocina: item.val().cocina,
                                tipoproducto: item.val().tipoproducto,
                                operacion: item.val().operacion,
                                icbper: 'false',
                                controstock: item.val().controstock,
                                cargoxconsumo: item.val().cargoxconsumo,
                                uuid: item.val().uuid
                            })
                        }

                    })
                    store.commit("dialogoprogress")
                    this.dialogoMesas = false
                    if (this.separacuentas) {
                        this.dialogo_separa = true
                    }
                })

            }
        },
        copiadelivery(id) {
            this.idmesa = id
            buscaComandaDelivery(id).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.listaproductos.push({
                        id: item.val().id,
                        codbarra: item.val().id,
                        cantidad: item.val().cantidad,
                        nombre: item.val().nombre,
                        medida: item.val().medida,
                        categoria: item.val().categoria,
                        precio: item.val().precio,
                        stock: item.val().stock,
                        precioedita: item.val().precio,
                        preciodescuento: item.val().preciodescuento,
                        costo: 0,
                        cocina: item.val().cocina,
                        tipoproducto: item.val().tipoproducto,
                        operacion: item.val().operacion,
                        icbper: 'false',
                        controstock: item.val().controstock,
                        cargoxconsumo: item.val().cargoxconsumo
                    })
                })
                store.commit("dialogoprogress")
                this.dialogoMesas = false
            })

        },
        sumaproductos(item) {
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].id == item.id) {
                    if (this.listaproductos[i].cantidad >= this.listaproductos[i].stock &&
                        this.listaproductos[i].controstock && store.state.configuracion.inventario
                    ) {
                        store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
                    } else {
                        this.listaproductos[i].cantidad = this.listaproductos[i].cantidad + 1
                        this.codigoedita = i
                    }
                }
            }
        },
        editaProducto(id) {

            this.dialogoProducto = true
            for (var i = 0; i < this.listaproductos.length; i++) {
                if (this.listaproductos[i].id == id) {
                    this.codigoedita = i
                    this.cantidadEdita = this.listaproductos[i].cantidad
                    this.precioedita = this.listaproductos[i].precioedita
                    this.preciodescuento = this.listaproductos[i].preciodescuento
                    this.nombreEdita = this.listaproductos[i].nombre
                }
            }
        },
        suma() {

            if (this.cantidadEdita >= this.listaproductos[this.codigoedita].stock &&
                this.listaproductos[this.codigoedita].controstock && store.state.configuracion.inventario
            ) {
                store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
            } else {
                this.cantidadEdita = parseInt(this.cantidadEdita) + 1
            }
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        grabaEdita() {
            if (parseFloat(this.cantidadEdita) > parseFloat(this.listaproductos[this.codigoedita].stock) &&
                this.listaproductos[this.codigoedita].controstock && store.state.configuracion.inventario
            ) {
                store.commit('dialogosnackbar', 'NO TIENE MAS STOCK')
            } else {
                if (this.preciodescuento == '') {
                    this.preciodescuento = 0
                }
                if (parseFloat(this.listaproductos[this.codigoedita].precioedita) >= parseFloat(this.preciodescuento.toString().trim())) {
                    this.listaproductos[this.codigoedita].cantidad = this.cantidadEdita.toString().trim()
                    this.listaproductos[this.codigoedita].precioedita = this.redondear(this.precioedita.toString().trim())
                    this.listaproductos[this.codigoedita].preciodescuento = this.redondear(this.preciodescuento.toString().trim())
                    this.listaproductos[this.codigoedita].nombre = this.nombreEdita.toString().trim()
                    this.dialogoProducto = false
                } else {
                    store.commit('dialogosnackbar', 'REVISE DESCUENTO')
                }
            }
        },

        eliminaedita() {
            setTimeout(() => this.set_focus(), 300);
            if (this.separacuentas) {
                this.listaproductos = []
                this.separacuentas = false
            }
            if (this.listaproductos.length <= 1) {
                console.log(this.listaproductos.length)
                this.idmesa = 'xx'
            }
            this.listaproductos.splice(this.codigoedita, 1)
            this.dialogoProducto = false
        },
        set_focus() {
            this.$refs.cod_barra.focus();
        },
        buscarArray(array, id) {
            var a = ''
            for (var i = 0; i < array.length; i++) {
                if (array[i].id == id) {
                    a = array[i]
                }
            }
            return a
        },
        sumaTotal() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                suma = suma + (this.listaproductos[i].cantidad * this.listaproductos[i].precioedita)
            }
            this.totalDocumento = suma.toFixed(2)
            return suma.toFixed(2)
        },
        sumaDescuentos() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                suma = suma + parseFloat(this.listaproductos[i].preciodescuento)
            }
            this.totalDescuento = this.redondear(suma)
            return this.redondear(suma)
        },
        genera_comprobante(data) {
            if (this.comparafecha()) {
                store.commit("dialogoprogress")
                if (this.nombreCompleto == '') {
                    this.nombreCompleto = 'CLIENTES VARIOS'
                }
                if (this.numero == '') {
                    this.numero = '00000000'
                    this.documento = "DNI"
                }
                obtenContador().once("value").then((snapshot) => {
                    var contadores = snapshot.val()
                    if (this.documento == "DNI") {
                        var doccliente = "1" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                    }
                    if (this.documento == "RUC") {
                        var doccliente = "6" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                    }
                    if (this.documento == "Pasaporte") {
                        var doccliente = "7" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                    }
                    if (this.documento == "Carnet de Extranjeria") {
                        var doccliente = "4" // 6 ruc --4 carnet --7 pasaporte -- 1 DNI
                    }
                    if (data == "1") {
                        var tipocomprobante = 'T'
                        var cod_comprobante = '00'
                        var correlativo = contadores.ordenticket
                        var serie = store.state.seriesdocumentos.ticket
                        var automata = 'NO'
                        var estado = 'aprobado'
                    }
                    if (data == "2") { //Catálogo No. 01: Código de Tipo de documento
                        var tipocomprobante = 'B'
                        var cod_comprobante = '03'
                        var correlativo = contadores.ordenboleta
                        var serie = store.state.seriesdocumentos.boleta
                        var automata = ''
                        var estado = 'PENDIENTE'
                    }
                    if (data == "3") { //Catálogo No. 01: Código de Tipo de documento 
                        var tipocomprobante = 'F'
                        var cod_comprobante = '01'
                        var correlativo = contadores.ordenfactura
                        var serie = store.state.seriesdocumentos.factura
                        var automata = ''
                        var estado = 'PENDIENTE'
                    }
                    var totalcuenta = this.sumaTotal()
                    var totaldescuentos = this.sumaDescuentos()
                    var fechahoy = this.verdate()
                    var array_cabecera = {
                        serie: serie,
                        correlativoDocEmitido: correlativo,
                        numeracion: tipocomprobante + correlativo,
                        tipoDocumento: this.documento,
                        cod_tipoDocumento: doccliente,
                        dni: this.numero,
                        cliente: this.nombreCompleto,
                        direccion: this.direccion,
                        telefono: this.telefono,
                        observacion: this.observacion,
                        nomempleado: this.empleado,
                        dniempleado: this.dniempleado,
                        referenciacliente: this.refcliente,
                        tipocomprobante: tipocomprobante,
                        cod_comprobante: cod_comprobante,
                        automata: automata,
                        estado: estado,
                        mensajeSunat: '',
                        hash: '',
                        documentId: '',
                        fecha: fechahoy,
                        vencimientoDoc: fechahoy,
                        modopago: this.modo_pago,
                        forma_pago: "Contado",
                        cuotas: '',
                        total: totalcuenta,
                        descuentos: totaldescuentos
                    }

                    cobrar_js(array_cabecera, this.listaproductos).then((r) => {
                        store.commit("dialogoprogress")
                        if (store.state.configuracion.flujocaja) {
                            var tot = parseFloat(totalcuenta) - parseFloat(totaldescuentos)
                            this.genera_flujo(contadores, this.modo_pago, tot, array_cabecera.numeracion).then(() => {
                                this.limpia_campos()
                            })
                        } else {
                            this.limpia_campos()
                        }
                    })

                })
            } else {
                store.commit('dialogosnackbar', 'FECHA EXCEDE EL LIMITE PERMITIDO')
            }
        },
        genera_flujo(contadores, modo, monto, observacion) {
            var fecha = moment().unix()
            var flujo = {
                id: contadores.flujocaja,
                operacion: 'ingreso',
                observacion: 'VENTA - ' + observacion,
                modo: modo,
                fecha: fecha,
                total: monto,
                estado: 'activo',
                responsable: store.state.permisos.correo.slice(0, -13),
                sujeto: store.state.permisos.correo.slice(0, -13),
            }
            var c = nuevoflujo(flujo.id, flujo).then(() => {
                var cont = (parseInt(contadores.flujocaja) + 1).toString().padStart(4, 0)
                var a = sumaContador('flujocaja', cont).then(() => {
                    return true
                })
                return a
            })
            return c

        },

        comparafecha() {
            var hoy = moment(String(new Date())).add(-5, 'd').format('YYYY-MM-DD')
            var fecha = moment(String(this.date)).format('YYYY-MM-DD')
            if (moment(fecha).isAfter(hoy)) {
                return true
            } else {
                return false
            }
        },
        limpia_campos() {
            if (!this.separacuentas) {
                if (this.idmesa != 'xx') {
                    if (this.esdelivery) {
                        eliminaComandaDelivery(this.idmesa)
                        eliminaDelivery(this.idmesa)
                    } else {
                        grabaEstadomesa(this.idmesa, "#46FF00")
                        grabaObservacionMesa(this.idmesa, "")
                        eliminaComanda(this.idmesa)
                    }
                }
            } else {
                this.elimina_separa()
            }
            this.listaproductos = []
            this.documento = 'DNI',
                this.numero = ''
            this.nombreCompleto = ''
            this.direccion = ''
            this.telefono = ''
            this.empleado = ''
            this.dniempleado = ''
            this.observacion = ''
            this.refcliente = ''
            this.date = moment(String(new Date)).format('YYYY-MM-DD')
            setTimeout(() => this.set_focus(), 300);
        },
        corremodoPago_r() {
            var totalcuenta = this.sumaTotal()
            var totaldescuentos = this.sumaDescuentos()
            var modopagos = [{
                modo: 'EFECTIVO',
                monto: this.redondear(totalcuenta - totaldescuentos)
            }]
            return modopagos
        },

        sumaTotalmodopago() {
            var suma = 0
            for (var i = 0; i < this.modopagos.length; i++) {
                suma = suma + parseFloat(this.modopagos[i].monto)
            }
            return parseFloat(suma).toFixed(2)
        },

        conviertefecha(date) {
            return moment.unix(date).format('YYYY-MM-DD')
        },
        redondear(valor) {
            return parseFloat(valor).toFixed(store.state.configuracion.decimal)
        },
        verdate() {
            var fecha = moment(String(this.date)) / 1000
            if (moment(String(new Date())).format('YYYY-MM-DD') == moment(String(this.date)).format('YYYY-MM-DD')) {
                fecha = moment().unix()
            }
            return fecha

        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        buscaMesa() {
            store.commit("dialogoprogress")
            this.mesasActivas = []
            this.consultacomanda().then((r) => {
                //console.log(r)
                store.commit("dialogoprogress")
                this.dialogoMesas = true
            })
        },
        consultacomanda() {
            var a = allComandas().once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let key = item.key
                        this.mesasActivas.push({
                            numeromesa: key,
                            cliente: key
                        })
                    });
                }
                return true
            })
            return a
        },
        buscaDelivery() {
            store.commit("dialogoprogress")
            this.mesasActivas = []
            this.esdelivery = true
            this.consultadelivery().then((r) => {
                store.commit("dialogoprogress")
                this.dialogoMesas = true
            })

        },
        consultadelivery() {
            var a = alldelivery().once("value").then((snapshot) => {
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let key = item.key
                        this.mesasActivas.push({
                            numeromesa: key,
                            cliente: item.val().cliente
                        })
                    });
                }
                return true
            })
            return a
        },
        irFlujo() {
            this.$router.push({
                path: '/flujocaja'
            })
        },
        BuscarDocumento() {
            if (this.numero != '' && this.documento == "RUC" || this.documento == "DNI" &&
                this.numero.length == 8 || this.numero.length == 11) {
                store.commit("dialogoprogress")

                if (this.numero.length == 11) {
                    this.documento = "RUC"
                } else {
                    this.documento = "DNI"
                }
                if (store.state.configuracion.dataclientes) {
                    buscaCliente()
                        .orderByChild("documento")
                        .equalTo(this.numero)
                        .once("value")
                        .then((snapshot) => {
                            if (snapshot.exists()) {
                                snapshot.forEach((item) => {
                                    this.nombreCompleto = item.val().nombre
                                    this.direccion = item.val().direccion
                                    this.refcliente = item.val().referencia
                                    this.telfcliente = item.val().telefono
                                })
                                store.commit("dialogoprogress")
                            } else {
                                this.consultaApiPeru()
                            }
                        })
                } else {
                    this.consultaApiPeru()
                }

            } else {
                if (store.state.configuracion.dataclientes) {

                    store.commit('dialogoClientesnuevos')
                } else {
                    store.commit('dialogosnackbar', 'INGRESE DOCUMENTO')
                }

            }
        },
        consultaApiPeru() {
            var self = this,
                token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress"),
                    self.llenardatos(response.data.data)
                ))
                .catch(error => {
                    store.commit("dialogoprogress"),
                        store.commit('dialogosnackbar', 'Documento Invalido')
                })
        },
        llenardatos(data) {
            if (this.documento == 'DNI') {
                this.nombreCompleto = data.nombre_completo
                if (Boolean(data.direccion)) {
                    this.direccion = data.direccion
                }
                this.telfcliente = ''
                this.refcliente = ''
            }
            if (this.documento == 'RUC') {
                this.nombreCompleto = data.nombre_o_razon_social
                if (Boolean(data.direccion_completa)) {
                    this.direccion = data.direccion
                }
                this.telfcliente = ''
                this.refcliente = ''
            }
        },
        BuscarEmpleado() {
            allEmpleados().once("value").then((snapshot) => {
                let array = [];
                snapshot.forEach((item) => {
                    let key = item.key;
                    let data = item.val();
                    array.push(data);
                });

                this.empleadoslista = array
                this.dialogoempleado = true
            })
        },
        agregaEmpleado(item) {
            this.empleado = item.nombre
            this.dniempleado = item.documento
            this.dialogoempleado = false
        },
    },
    computed: {
        listafiltradaclientes() {
            return this.clienteslista.filter((item) =>
                (item.alias + item.nombre + item.telefono)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        },
        cuadravuelto() {
            var resta = 0
            resta = this.paravuelto - this.calculavuelto
            if (resta < 0) {
                return "MONTO MENOR A S/.0"
            } else {
                return "El vuelto debe ser: S/." + resta.toFixed(2)
            }

        },
        listafiltradaempleados() {
            return this.empleadoslista.filter((item) =>
                (item.alias + item.nombre + item.telefono)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        },

    },

}
</script>
