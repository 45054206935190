<template>
    <div class="pa-3">
        <v-row>
            <!-- Columna lateral para pantallas más anchas -->
            <v-col cols="12" md="4"
                v-if="$vuetify.breakpoint.mdAndUp || ($vuetify.breakpoint.smAndDown && $vuetify.breakpoint.width > $vuetify.breakpoint.height)">
                <cat_fijo @agrega_lista="agregaCatalogo($event)"></cat_fijo>
            </v-col>

            <v-col cols="12" md="8">
                <v-row class="mx-auto text-center">
                    <v-col cols="6">
                        <h3 class="text-center mb-3">Mesa {{ $route.params.cliente }}</h3>
                    </v-col>
                    <v-col cols="6">
                        <h3 class="text-center mb-3">Tot: S/.{{ sumaTotal() }}</h3>
                    </v-col>
                </v-row>

                <v-card elevation="5">
                    <v-row class="mx-auto text-center">
                        <v-col cols="3">
                            <v-icon @click="regresa()" color="red" large>mdi-keyboard-return</v-icon>
                        </v-col>
                        <v-col cols="3">
                            <v-icon @click="dial_catalogo = !dial_catalogo" color="orange"
                                large>mdi-folder-open</v-icon>
                        </v-col>
                        <v-col cols="3">
                            <v-icon @click="dialogAgrega = !dialogAgrega" color="blue" large>mdi-plus</v-icon>
                        </v-col>
                        <v-col cols="3">
                            <v-icon @click="grabaComanda()" color="green" large>mdi-content-save</v-icon>
                        </v-col>
                    </v-row>

                    <v-simple-table dense fixed-header height="65vh">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Nombre</th>
                                    <th class="text-left">Observación</th>
                                    <th class="text-left">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in listaproductos" :key="item.uuid">
                                    <td class="text-body-2">
                                        <strong class="red--text">{{ item.cantidad }} x</strong> {{ item.nombre
                                        }}
                                        <v-icon color="green" class="ml-6" @click="abre_obs(item)">mdi-magnify</v-icon>
                                        <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                            Gratuita</strong>
                                    </td>
                                    <td class="text-body-2">
                                        <v-chip-group active-class="primary--text" column dense>
                                            <v-chip dense v-for="tag in item.observacion" :key="tag">{{ tag
                                                }}</v-chip>
                                        </v-chip-group>
                                    </td>
                                    <td v-if="item.operacion == 'GRATUITA'" class="text-body-2">S/0.0
                                        <v-icon color="red" class="ml-2"
                                            @click="editaProducto(item)">mdi-pencil</v-icon>
                                    </td>
                                    <td v-if="item.operacion != 'GRATUITA'" class="text-body-2">{{ (item.precio *
                                        item.cantidad).toFixed(2) }}
                                        <v-icon color="red" class="ml-2"
                                            @click="editaProducto(item)">mdi-pencil</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>

                <v-dialog v-model="dialogoProducto" max-width="390">
                    <div>
                        <v-system-bar window dark>
                            <v-icon @click="dialogoProducto = false">mdi-close</v-icon>
                        </v-system-bar>
                    </div>
                    <v-card class="pa-3">

                        <v-row class="mx-auto mt-4 text-center">

                            <v-col cols="4" xs="4">
                                <v-icon :disabled="!permite_editar" @click="suma()" color="green">mdi-plus</v-icon>
                            </v-col>

                            <v-col cols="4" xs="4">
                                <v-text-field :disabled="!permite_editar" type="number" outlined v-model="cantidadEdita"
                                    label="Cantidad"></v-text-field>
                            </v-col>
                            <v-col cols="4" xs="4">
                                <v-icon :disabled="!permite_editar" @click="resta()" color="red">mdi-minus</v-icon>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn :disabled="!permite_editar" color="red darken-1" text @click="eliminaedita()">
                                Elimina
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="grabaEdita()">
                                Graba
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <lista_observ v-if="dialog_prede" :data="item_selecto" @cierra="dialog_prede = false"
                    @graba="graba_obs($event)" />
                <cateogrias v-if="dial_catalogo" @cierra="dial_catalogo = false" @array="agregaCatalogo($event)" />
            </v-col>
        </v-row>
        <agrega_producto v-if="dialogAgrega" @cierra="dialogAgrega = false"
            @agrega_lista="agregaCatalogo($event), dialogAgrega = false" />
    </div>
</template>

<script>
import {
    nuevaComandaDelivery,
    buscaComandaDelivery,
    enviaCocina,
    enviaBarra,
} from '../../db'
import agrega_producto from '@/views/restaurante/agrega_producto'
import axios from "axios"
import store from '@/store/index'
import moment from 'moment'
import cateogrias from '@/components/dialogos/dialogocatalogo'
import lista_observ from '@/views/restaurante/lista_observ'
import cat_fijo from '@/components/catalogo_fijo'
import {
    pdfprecuenta,
    pc_print
} from '../../pdf_precuentas'
import {
    genera_precuenta_v2
} from '../../pdf_precuentas_v2'
export default {
    components: {
        cateogrias,
        lista_observ,
        cat_fijo,
        agrega_producto
    },
    data() {
        return {
            dial_catalogo: false,
            dialogAgrega: false,
            dialogoProducto: false,
            dialog_prede: false,
            listaproductos: [],
            cantidadEdita: '',
            observacion: '',
            permite_editar: false,
            item_selecto: '',
            buscar: '',
            cantidad: '',
            producto_selecto: '',
            categoriaselecta: ''
        }
    },
    async beforeCreate() {
        var snapshot = await buscaComandaDelivery(this.$route.params.id).once("value")
        if (snapshot.exists()) {
            this.listaproductos = snapshot.val()
        }
    },
    async created() {

    },
    computed: {
        listafiltrada() {
            var invent = store.state.productos
            return invent.filter((item) =>
                (item.activo) == true)
                .filter((item) => (item.categoria)
                    .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
                .filter((item) => (item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))

        }
    },
    methods: {
        async grabaComanda() {
            store.commit("dialogoprogress")
            var lista_pendiente = this.listaproductos.filter(item => item.estado == 'pendiente')
            //var lista_pendiente = this.listaproductos
            var array_cocina = lista_pendiente.filter(item => item.cocina == true)
            var array_barra = lista_pendiente.filter(item => item.barra == true)
            var array_cocina2 = lista_pendiente.filter(item => item.cocina2 == true)
            var array_barra2 = lista_pendiente.filter(item => item.barra2 == true)
            for (var i = 0; i < this.listaproductos.length; i++) {
                this.listaproductos[i].estado = 'enviado'
            }
            await nuevaComandaDelivery(this.$route.params.id, this.listaproductos)
            if (store.state.configImpresora.impresoracocina || store.state.configImpresora.impresora_auto) {
                await this.envio_impresora(array_cocina, array_barra, array_cocina2, array_barra2)
            } else {
                await this.envio_pantallas(array_cocina, array_barra)
            }

            //await editaaMesa(this.$route.params.id + '/estado', "OCUPADA")
            store.commit("dialogoprogress")
            this.regresa()
        },
        async envio_pantallas(cocina, barra) {
            if (cocina != '') {
                var arrayenvio_cocina = {
                    fecha: moment().unix(),
                    usuario: store.state.permisos.nombre,
                    idmesa: this.$route.params.cliente,
                    data: cocina,
                    delivery: true
                }
                await enviaCocina(arrayenvio_cocina)
            }
            if (barra != '') {
                var arrayenvio_barra = {
                    fecha: moment().unix(),
                    usuario: store.state.permisos.nombre,
                    idmesa: this.$route.params.cliente,
                    data: barra,
                    delivery: true
                }
                await enviaBarra(arrayenvio_barra)
            }
            return true
        },
        async envio_impresora(cocina, barra, cocina2, barra2) {
            if (cocina == '' && barra == '' && cocina2 == '' && barra2 == '') {
                return
            }
            var fecha = moment(String(new Date())).format('YYYY-MM-DD hh:mm')
            var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
            if (store.state.configImpresora.impresora_auto) {

                const procesarPreCuenta = (area, array) => {
                    if (array.length > 0) {
                        console.log(area)
                        genera_precuenta_v2(
                            { delivery: true, id_mesa: this.$route.params.id },
                            array,
                            '80',
                            false,
                            responsable,
                            area
                        );
                    }
                };
                procesarPreCuenta("Cocina1", cocina);
                procesarPreCuenta("Barra1", barra);
                procesarPreCuenta("Cocina2", cocina2);
                procesarPreCuenta("Barra2", barra2);
            } else {
                if (cocina != '') {
                    for (var i = 0; i < cocina.length; i++) {

                        cocina[i].observacion = cocina[i].observacion ? cocina[i].observacion.toString() : ''
                    }
                }
                if (barra != '') {
                    for (var i = 0; i < barra.length; i++) {
                        barra[i].observacion = barra[i].observacion ? barra[i].observacion.toString() : ''
                    }
                }
                if (cocina2 != '') {
                    for (var i = 0; i < cocina2.length; i++) {
                        cocina2[i].observacion = cocina2[i].observacion ? cocina2[i].observacion.toString() : ''
                    }
                }
                if (barra2 != '') {
                    for (var i = 0; i < barra2.length; i++) {
                        barra2[i].observacion = barra2[i].observacion ? barra2[i].observacion.toString() : ''
                    }
                }
                var a = await pc_print(this.$route.params.cliente, responsable.substring(0, responsable.indexOf('@')),
                    fecha, 0, cocina, barra, cocina2, barra2)
                console.log(a)
                return true
            }


        },

        api_rest(data, metodo) {

            var a = axios({
                method: 'POST',
                url: 'https://us-central1-mitienda-f5ef8.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/mitienda-f5ef8/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                console.log(response.data)
                return response
            })
            return a
        },
        sumaTotal() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                var data = this.listaproductos[i]
                if (data.operacion != 'GRATUITA') {
                    suma = suma + (data.cantidad * data.precio)
                }

            }
            return suma.toFixed(2)
        },
        abre_obs(val) {
            this.item_selecto = val
            this.dialog_prede = true
        },
        agregaCatalogo(value) {
            if (!Boolean(value.cocina2)) {
                value.cocina2 = false
            }
            if (!Boolean(value.barra2)) {
                value.barra2 = false
            }

            var arra = {
                uuid: this.create_UUID().substring(29),
                id: value.id,
                operacion: value.operacion,
                cantidad: value.cantidad,
                nombre: value.nombre,
                precio: value.precio,
                idmesa: this.$route.params.id,
                estado: 'pendiente',
                observacion: value.observacion || '',
                cocina: value.cocina,
                barra: value.barra,
                cocina2: value.cocina2,
                barra2: value.barra2,
            }
            this.listaproductos.push(arra)
            if (store.state.configuracion.stock_comandas) {
                var array = {
                    metodo: 'RESTA',
                    array: arra
                }
                this.api_rest(array, 'modifica_stock_unitario')
            }
        },
        graba_obs(val) {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos[pos].observacion = val
            this.dialog_prede = false
        },
        editaProducto(val) {
            this.permite_editar = false
            if (val.estado == 'pendiente') {
                this.permite_editar = true
            } else {
                if (!store.state.permisos.editar_comanda) {
                    this.permite_editar = false
                } else {
                    this.permite_editar = true
                }
            }
            this.item_selecto = val
            this.cantidadEdita = val.cantidad
            this.dialogoProducto = true
        },
        grabaEdita() {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            if (store.state.configuracion.stock_comandas) {
                if (parseFloat(this.item_selecto.cantidad) > parseFloat(this.cantidadEdita)) {
                    var sum = parseFloat(this.item_selecto.cantidad) - parseFloat(this.cantidadEdita)
                    console.log(sum)
                    this.item_selecto.cantidad = sum
                    var array = {
                        metodo: 'SUMA',
                        array: this.item_selecto
                    }
                    this.api_rest(array, 'modifica_stock_unitario')
                } else {
                    if (parseFloat(this.item_selecto.cantidad) < parseFloat(this.cantidadEdita)) {
                        var sum = parseFloat(this.cantidadEdita) - parseFloat(this.item_selecto.cantidad)
                        console.log(sum)
                        this.item_selecto.cantidad = sum
                        var array = {
                            metodo: 'RESTA',
                            array: this.item_selecto
                        }
                        this.api_rest(array, 'modifica_stock_unitario')
                    }
                }

            }
            this.listaproductos[pos].cantidad = this.cantidadEdita
            this.dialogoProducto = false
        },
        eliminaedita() {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos.splice(pos, 1)
            if (store.state.configuracion.stock_comandas) {
                var array = {
                    metodo: 'SUMA',
                    array: this.item_selecto
                }
                this.api_rest(array, 'modifica_stock_unitario')
            }
            this.dialogoProducto = false
        },
        suma() {
            this.cantidadEdita = parseInt(this.cantidadEdita) + 1
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        regresa() {
            this.$router.push({
                name: 'delivery'
            })
        }
    }
}
</script>
