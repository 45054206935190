<template>
    <div class="pa-4">
        <v-row>
            <v-col cols="6">
                <v-select outlined offset-y dense v-model="zona" :items="array_zonas" hide-details label="ZONA "
                    @change="handleZonaChange"></v-select>
            </v-col>
            <v-col cols="6">
                <v-menu offset-y block>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" block dark v-bind="attrs" v-on="on">
                            OPCIONES
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="dial_gestion = !dial_gestion">
                            <v-list-item-title>Gestionar Mesas</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="dial_lista_m = !dial_lista_m">
                            <v-list-item-title>Lista Mozos</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="dial_borrado = !dial_borrado">
                            <v-list-item-title>Histo.Borrado</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="abre_QR_mosos()">
                            <v-list-item-title>QR.Mosos</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-row dense class="pa-2">
            <v-col v-for="item in mesas_filtradas" :key="item.id_mesa" cols="4" class="pa-1" md="2" sm="2" xs="4">
                <v-card height="70" :color="item.color" elevation="5" @click.prevent="click_mesa(item)">
                    <v-card-text>
                        <h2 class="black--text">{{ item.nom_mesa }}</h2>
                        <p class="white--text">{{ item.ref }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dial_mesa" max-width="550">

            <v-toolbar dark dense>
                <v-toolbar-title>Mesa {{ mesa_selecta.nom_mesa }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title>S/{{ total_mesa }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dial_mesa = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card">
                <v-card-text>
                    <v-row dense class="text-center mt-1">
                        <v-col cols="6" sm="4">
                            <v-card @click.prevent="editar_mesa()">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/convertir.png"></v-img>
                                    <h5>Editar</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-card @click.prevent="comanda()">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/disc.png"></v-img>
                                    <h5>Comanda</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-card @click.prevent="pre_cuenta(true)">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/pago.png"></v-img>
                                    <h5>Precuenta</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-card @click.prevent="transferir_mesa()" :disabled="!$store.state.permisos.trans_comanda">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/transfer.png"></v-img>
                                    <h5>Transferir</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-card @click.prevent="unir_mesa()" :disabled="!$store.state.permisos.unir_comanda">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/mesa.png"></v-img>
                                    <h5>Unir</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-card @click.prevent="dial_anular = true"
                                :disabled="!$store.state.permisos.limpia_comanda">
                                <v-container>
                                    <v-img class="mx-auto" height="30" width="30" src="/anular.png"></v-img>
                                    <h5>Limpiar</h5>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-simple-table fixed-header height="30vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Producto</th>
                                <th class="text-left">Obs</th>
                                <th class="text-left">Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_comanda" :key="item.uuid">
                                <td><strong class="red--text">{{ item.cantidad }} x</strong> x {{ item.nombre }}
                                    <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                        Gratuita</strong>

                                </td>
                                <td>{{ item.observacion }}</td>
                                <td v-if="item.operacion != 'GRATUITA'">{{ (item.precio * item.cantidad).toFixed(2) }}
                                </td>
                                <td v-if="item.operacion == 'GRATUITA'">0.00
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogTransferir" max-width="390">

            <v-toolbar dark dense>
                <v-toolbar-title>Transferir Mesa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogTransferir = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card pa-2">
                <v-card-text>
                    <v-select :items="mesa_destino" label="MESA DESTINO" dense outlined
                        v-model="mesadestino"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="transferirmesa()">Transferir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_unir" max-width="390">

            <v-toolbar dark dense>
                <v-toolbar-title>Unir Mesa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog_unir = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card pa-2">
                <v-card-text>
                    <v-select :items="mesa_destino" label="MESA DESTINO" dense outlined
                        v-model="mesadestino"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="unirmesa()">Unir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_unir" max-width="390">

            <v-toolbar dark dense>
                <v-toolbar-title>Unir Mesa</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog_unir = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card pa-2">
                <v-card-text>
                    <v-select :items="mesa_destino" label="MESA DESTINO" dense outlined
                        v-model="mesadestino"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="unirmesa()">Unir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_mozo" max-width="410">

            <v-toolbar dark dense>
                <v-toolbar-title>Selecciona Mozo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dial_mozo = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card pa-2">
                <v-card-text class="mt-2">
                    <v-select :items="mozos_filtradas" item-text="nom_mozo" label="Mozo Responsable" dense outlined
                        v-model="mozo_resp"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="Graba_mozo()">Continuar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_anular" max-width="410">

            <v-toolbar dark dense>
                <v-toolbar-title>seguro que desea anular? </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dial_anular = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card pa-2">
                <v-textarea dense class="" outlined v-model="obs" auto-grow filled color="deep-purple"
                    label="Observacion" rows="1"></v-textarea>
                <v-card-actions class="mt-n3">
                    <v-btn color="red" text @click="anular_mesa()">Anular</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text @click="dial_anular = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_qr_moso" max-width="410">

            <v-toolbar dark dense>
                <v-spacer></v-spacer>
                <v-btn icon @click="dial_qr_moso = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="no-hover-card pa-2">
                <v-img :src="imagen"></v-img>
            </v-card>
        </v-dialog>
        <gestion_mesas v-if="dial_gestion" @cierre="dial_gestion = false"></gestion_mesas>
        <lista_mosos v-if="dial_lista_m" @cierre="dial_lista_m = false"></lista_mosos>
        <borrado v-if="dial_borrado" @cierre="dial_borrado = false"></borrado>
    </div>
</template>

<script>
import {
    pdfprecuenta,
    pc_print,
    genera_precuenta
} from '../../pdf_precuentas'
import QR from 'qrcode-base64'
import {
    genera_precuenta_v2
} from '../../pdf_precuentas_v2'
import axios from "axios"
import {
    allMesa,
    allMozo,
    buscaComanda,
    nuevaComanda,
    editaaMesa,
    guard_historial
} from '../../db'
import {
    envia_host
} from '../../host_impresora'
import moment from 'moment'
import gestion_mesas from '@/views/restaurante/gestion_mesa'
import lista_mosos from '@/views/restaurante/lista_mosos'
import borrado from '@/views/restaurante/dial_borrado'
import store from '@/store/index'
export default {
    components: {
        gestion_mesas,
        lista_mosos,
        borrado
    },
    data() {
        return {
            dial_qr_moso: false,
            dial_borrado: false,
            dial_anular: false,
            dialogTransferir: false,
            dial_lista_m: false,
            dialog_unir: false,
            dial_mesa: false,
            dial_gestion: false,
            dial_mozo: false,
            array_zonas: ["TODAS", "ZONA 1", "ZONA 2", "ZONA 3", "ZONA 4", "TERRAZA", "SALON", "CAMPO"],
            zona: 'TODAS',
            array_mesas: [],
            mesa_selecta: '',
            mesa_origen: [],
            mesa_destino: [],
            mesadestino: '',
            mesaorigen: '',
            array_comanda: [],
            permite_editar: false,
            array_mozos: [],
            mozo_resp: '',
            obs: '',
            total_mesa: '',
            imagen: []
        }
    },
    mounted() {
        allMesa().on("value", this.onDataChange);
        allMozo().on("value", this.onDataChange1);
    },
    beforeDestroy() {
        allMesa().off("value", this.onDataChange);
        allMozo().off("value", this.onDataChange1);
    },
    created() {
        this.zona = store.state.mesa_selecta
    },
    computed: {
        mesas_filtradas() {
            var filtro = this.array_mesas
            if (this.zona == 'TODAS') {
                return filtro
            } else {
                return filtro.filter(item => item.zona_mesa == this.zona)
            }
        },
        mozos_filtradas() {
            var filtro = this.array_mozos
            if (this.zona == 'TODAS') {
                return filtro
            } else {
                return filtro.filter(item => item.zona_mesa == this.zona)
            }
        },
    },
    methods: {
        async abre_QR_mosos() {
            var data = store.state.serv_imp.ip.split(":")[0] + ":"

            this.imagen = await QR.drawImg('http://' + data + '4040', {
                typeNumber: 4,
                errorCorrectLevel: 'M',
                size: 500
            })
            this.dial_qr_moso = true
        },
        async Graba_mozo() {
            await editaaMesa(this.mesa_selecta.id_mesa + '/mozo', this.mozo_resp),

                this.$router.push({
                    path: "/comanda_mesa/" + this.mesa_selecta.id_mesa + '/' + this.mozo_resp
                })
        },
        handleZonaChange() {
            store.commit('mesa_selecta', this.zona)
        },
        onDataChange(items) {
            this.array_mesas = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.estado == 'PENDIENTE') {
                    data.color = '#46FF00'
                } else {
                    data.color = '#FF0000'
                }
                array.push(data);
            });
            this.array_mesas = array;
        },
        onDataChange1(items) {
            this.array_mozos = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_mozos = array;
        },
        editar_mesa() {
            this.$router.push({
                path: "/comanda_mesa/" + this.mesa_selecta.id_mesa + '/' + this.mesa_selecta.mozo
            })
        },
        async anular_mesa() {
            //if (confirm('seguro que desea anular?')) {
            this.dial_anular = false
            store.commit("dialogoprogress")
            var array = {
                id_mesa: this.mesa_selecta.id_mesa,
            }
            if (store.state.configuracion.stock_comandas) {
                var arrays = {
                    metodo: 'SUMA',
                    array: this.array_comanda
                }
                this.api_rest(arrays, 'modifica_stock_array')
            }
            if (store.state.configuracion.guard_mesa) {
                var arrays = {
                    fecha: moment().unix(),
                    obs: this.obs,
                    modo: 'limpiar',
                    resp: store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@')),
                    array: this.array_comanda,
                    mozo: this.mesa_selecta.mozo,
                    id_mesa: this.mesa_selecta.id_mesa
                }
                await guard_historial(arrays)
            }
            console.log(store.state.configuracion)
            this.api_rest(array, 'borrar_mesa')
            store.commit("dialogoprogress")
            this.dial_mesa = false
            this.obs = ''
            //   }
        },
        unir_mesa() {
            var array = this.mesas_filtradas
            var destino = []
            for (var i = 0; i < array.length; i++) {
                if (array[i].estado != 'PENDIENTE') {
                    destino.push(array[i].nom_mesa)
                }
            }
            this.mesa_destino = destino
            this.dialog_unir = true
        },
        async unirmesa() {
            if (confirm('seguro que desea unir?')) {
                store.commit("dialogoprogress")
                var array = {
                    id_mesa: this.mesa_selecta.id_mesa,
                    mesadestino: this.mesadestino
                }
                this.api_rest(array, 'unir')
                store.commit("dialogoprogress")
                this.dialog_unir = false
                this.dial_mesa = false
            }
        },
        transferir_mesa() {
            var array = this.mesas_filtradas
            var destino = []
            for (var i = 0; i < array.length; i++) {
                if (array[i].estado == 'PENDIENTE') {
                    destino.push(array[i].nom_mesa)
                }
            }
            this.mesa_destino = destino
            this.dialogTransferir = true
        },
        async transferirmesa() {
            if (confirm('seguro que desea Transferir?')) {
                store.commit("dialogoprogress")
                var array = {
                    id_mesa: this.mesa_selecta.id_mesa,
                    mesadestino: this.mesadestino
                }
                this.api_rest(array, 'transferir')
                store.commit("dialogoprogress")
                this.dialogTransferir = false
                this.dial_mesa = false
            }
        },

        api_rest(data, metodo) {

            var a = axios({
                method: 'POST',
                url: 'https://us-central1-mitienda-f5ef8.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/mitienda-f5ef8/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                console.log(response.data)
                return response
            })
            return a
        },
        async click_mesa(val) {
            this.array_comanda = []
            if (val.estado == 'PENDIENTE') {
                if (store.state.configuracion.mosos_mesa) {
                    this.mesa_selecta = val
                    this.dial_mozo = true

                } else {
                    var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
                    this.$router.push({
                        path: "/comanda_mesa/" + val.id_mesa + '/' + responsable
                    })
                }
            } else {
                this.permite_editar = false
                if (store.state.configuracion.edita_comandas && !store.state.permisos.moduloempresa) {
                    this.permite_editar = false
                } else {
                    this.permite_editar = true
                }

                store.commit("dialogoprogress")
                var snapshot = await buscaComanda(val.id_mesa).once("value")
                if (snapshot.val()) {
                    this.array_comanda = snapshot.val()
                    this.total_mesa = this.array_comanda
                        .reduce((sum, item) =>
                            item.operacion !== 'GRATUITA'
                                ? sum + parseFloat(item.precio) * parseInt(item.cantidad, 10)
                                : sum,
                            0
                        )
                        .toFixed(2);


                }


                this.mesa_selecta = val
                this.dial_mesa = true
                store.commit("dialogoprogress")
            }
        },

        async comanda() {
            if (store.state.configuracion.mosos_mesa) {
                var responsable = this.mesa_selecta.mozo
            } else {
                var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
            }

            var lista_pendiente = this.array_comanda

            var array_cocina = lista_pendiente.filter(item => item.cocina == true)
            var array_barra = lista_pendiente.filter(item => item.barra == true)
            var array_cocina2 = lista_pendiente.filter(item => item.cocina2 == true)
            var array_barra2 = lista_pendiente.filter(item => item.barra2 == true)
            if (store.state.configImpresora.impresoracocina) {
                await this.envio_impresora(array_cocina, array_barra, array_cocina2, array_barra2)
                return
            }
            const procesarPreCuenta = (area, array) => {
                if (array.length > 0) {
                    console.log(area)
                    genera_precuenta_v2(
                        { delivery: false, id_mesa: this.mesa_selecta.id_mesa },
                        array,
                        '80',
                        false,
                        responsable,
                        area
                    );
                }
            };
            if (store.state.configImpresora.impresora_auto) {
                store.commit("dialogoprogress")
                procesarPreCuenta("Cocina1", array_cocina);
                procesarPreCuenta("Barra1", array_barra);
                procesarPreCuenta("Cocina2", array_cocina2);
                procesarPreCuenta("Barra2", array_barra2);
                await new Promise(resolve => setTimeout(resolve, 500))
                store.commit("dialogoprogress")
            } else {
                this.pre_cuenta(false);
            }
        },
        async pre_cuenta(val) {
            if (store.state.configuracion.mosos_mesa) {
                console.log(this.mesa_selecta)
                var responsable = this.mesa_selecta.mozo
            } else {
                var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
            }
            if (store.state.configImpresora.impresoracocina) {
                var array = {
                    delivery: false,
                    id_mesa: this.mesa_selecta.id_mesa,
                }
                genera_precuenta(array, this.array_comanda, '80', val, responsable)
                return
            }
            if (store.state.configImpresora.impresora_auto) {
                var lista_pendiente = this.array_comanda
                var array = {
                    delivery: false,
                    id_mesa: this.mesa_selecta.id_mesa,
                }
                var a = await genera_precuenta(array, lista_pendiente, '80', val, responsable)
                //console.log(a)
                //   envia_host(a, 'df.pdf', 'caja')
                // await this.envio_impresora(array_cocina, array_barra)
            } else {
                var array = {
                    delivery: false,
                    id_mesa: this.mesa_selecta.id_mesa,
                }

                genera_precuenta(array, this.array_comanda, '80', val, responsable)
            }
        },
        async envio_impresora(cocina, barra, cocina2, barra2) {
            if (store.state.configuracion.mosos_mesa) {
                //console.log(this.mesa_selecta)
                var responsable = this.mesa_selecta.mozo
            } else {
                var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
            }
            if (cocina == '' && barra == '' && cocina2 == '' && barra2 == '') {
                return
            }
            var fecha = moment(String(new Date())).format('YYYY-MM-DD hh:mm')

            if (cocina != '') {
                for (var i = 0; i < cocina.length; i++) {

                    cocina[i].observacion = cocina[i].observacion ? cocina[i].observacion.toString() : ''
                }
            }
            if (barra != '') {
                for (var i = 0; i < barra.length; i++) {
                    barra[i].observacion = barra[i].observacion ? barra[i].observacion.toString() : ''
                }
            }
            if (cocina2 != '') {
                for (var i = 0; i < cocina2.length; i++) {
                    cocina2[i].observacion = cocina2[i].observacion ? cocina2[i].observacion.toString() : ''
                }
            }
            if (barra2 != '') {
                for (var i = 0; i < barra2.length; i++) {
                    barra2[i].observacion = barra2[i].observacion ? barra2[i].observacion.toString() : ''
                }
            }
            var a = await pc_print(this.mesa_selecta.id_mesa, responsable,
                fecha, 0, cocina, barra, cocina2, barra2)
            console.log(a)
            return true
        }
    }
}
/*
 */
</script>

<style scoped>
.v-card {
    cursor: pointer;
    transition: transform 0.2s;
}

.v-card:hover {
    transform: scale(1.05);
}

.no-hover-card {
    cursor: default;
    transition: none;
}

.no-hover-card.v-card:hover {
    transform: none;
}
</style>
