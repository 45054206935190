<template>
    <div class="pa-3">
        <v-row>
            <!-- Columna lateral para pantallas más anchas -->
            <v-col cols="12" md="4"
                v-if="$vuetify.breakpoint.mdAndUp || ($vuetify.breakpoint.smAndDown && $vuetify.breakpoint.width > $vuetify.breakpoint.height)">
                <cat_fijo @agrega_lista="agregaCatalogo($event)"></cat_fijo>
            </v-col>

            <!-- Columna principal -->
            <v-col cols=" 12" md="8">
                <v-row class="mx-auto text-center">
                    <v-col cols="1">
                        <v-icon @click="regresa()" color="red" large>mdi-keyboard-return</v-icon>
                    </v-col>
                    <v-col cols="5">

                        <h4 class="text-h5 mb-3">Mesa {{ $route.params.id }}</h4>
                    </v-col>
                    <v-col cols="6">
                        <h4 class="text-h5 mb-3">Tot: S/.{{ sumaTotal() }}</h4>
                    </v-col>
                </v-row>
                <v-card class="elevation-5">
                    <v-row class="mx-auto text-center">
                        <v-col cols="3">
                            <v-icon @click="abre_cliente()" color="black" large>mdi-account-plus</v-icon>
                        </v-col>
                        <v-col cols="3">
                            <v-icon @click="dial_catalogo = !dial_catalogo" color="orange"
                                large>mdi-folder-open</v-icon>
                        </v-col>
                        <v-col cols="3">
                            <v-icon @click="dialogAgrega = !dialogAgrega" color="blue" large>mdi-plus</v-icon>
                        </v-col>
                        <v-col cols="3">
                            <v-icon @click="grabaComanda()" color="green" large>mdi-content-save</v-icon>
                        </v-col>

                    </v-row>
                    <v-simple-table dense fixed-header height="65vh">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Nombre</th>
                                    <th class="text-left">Observación</th>
                                    <th class="text-left">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in listaproductos" :key="item.uuid">
                                    <td class="text-body-2">
                                        <strong class="red--text">{{ item.cantidad }} x</strong> {{ item.nombre
                                        }}
                                        <v-icon color="green" class="ml-6" @click="abre_obs(item)">mdi-magnify</v-icon>
                                        <strong class="red--text" v-if="item.operacion == 'GRATUITA'"> -
                                            Gratuita</strong>
                                    </td>
                                    <td class="text-body-2">
                                        <v-chip-group active-class="primary--text" column dense>
                                            <v-chip dense v-for="tag in item.observacion" :key="tag">{{ tag
                                                }}</v-chip>
                                        </v-chip-group>
                                    </td>
                                    <td v-if="item.operacion == 'GRATUITA'" class="text-body-2">S/0.0
                                        <v-icon color="red" class="ml-2"
                                            @click="editaProducto(item)">mdi-pencil</v-icon>
                                    </td>
                                    <td v-if="item.operacion != 'GRATUITA'" class="text-body-2">{{ (item.precio *
                                        item.cantidad).toFixed(2) }}
                                        <v-icon color="red" class="ml-2"
                                            @click="editaProducto(item)">mdi-pencil</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>

                <v-dialog v-model="dialogoProducto" max-width="390">
                    <v-card>
                        <v-system-bar window dark>
                            <v-icon @click="dialogoProducto = false">mdi-close</v-icon>
                        </v-system-bar>
                        <v-card-text>
                            <v-row class="mx-auto mt-4 text-center">
                                <v-col cols="4" xs="4">
                                    <v-icon :disabled="!permite_editar" @click="suma()" color="green">mdi-plus</v-icon>
                                </v-col>
                                <v-col cols="4" xs="4">
                                    <v-text-field :disabled="!permite_editar" type="number" outlined
                                        v-model="cantidadEdita" label="Cantidad"></v-text-field>
                                </v-col>
                                <v-col cols="4" xs="4">
                                    <v-icon :disabled="!permite_editar" @click="resta()" color="red">mdi-minus</v-icon>
                                </v-col>
                            </v-row>
                            <v-card-actions>
                                <v-btn :disabled="!permite_editar" color="red darken-1" text
                                    @click="eliminaedita()">Eliminar</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" text @click="grabaEdita()">Guardar</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <agrega_producto v-if="dialogAgrega" @cierra="dialogAgrega = false"
                    @agrega_lista="agregaCatalogo($event), dialogAgrega = false" />
                <lista_observ v-if="dialog_prede" :data="item_selecto" @cierra="dialog_prede = false"
                    @graba="graba_obs($event)" />
                <cateogrias v-if="dial_catalogo" @cierra="dial_catalogo = false" @array="agregaCatalogo($event)" />

            </v-col>
        </v-row>


        <v-dialog v-model="dial_cliente" max-width="450px">
            <v-card>
                <v-system-bar window dark>
                    <v-icon @click="dial_cliente = !dial_cliente">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>Datos para Comprobante Electronico</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
                <v-card-title>
                    <v-row class="mt-n3" dense>
                        <v-col cols="4" xs="4">
                            <v-select outlined dense v-model="documento" :items="documentos" menu-props="auto"
                                hide-details label="Tipo Doc"></v-select>
                        </v-col>
                        <v-col cols="8" xs="8">
                            <v-text-field autofocus outlined dense type="number" v-model="numero" label="Numero"
                                append-icon="mdi-magnify" @click:append="BuscarDocumento()"
                                @keyup.enter="BuscarDocumento()"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field class="mt-n4" outlined dense v-model="nombreCompleto"
                        label="Nombres Completos"></v-text-field>
                    <v-text-field class="mt-n3" outlined dense v-model="direccion" label="Direccion"></v-text-field>
                    <v-text-field class="mt-n3" outlined dense v-model="observacion" label="Observacion"></v-text-field>
                    <v-radio-group class="mt-n1 mx-auto " v-model="tipocomprobante" row>
                        <v-radio label="Nota" value="T"></v-radio>
                        <v-radio label="Boleta" value="B"></v-radio>
                        <v-radio label="Factura" value="F"></v-radio>
                    </v-radio-group>
                    <v-btn color="success" @click="guarda_cliente()">Guardar</v-btn>
                </v-card-title>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    editaaMesa,
    busca_mesa,
    nuevaComanda,
    buscaComanda,
    enviaCocina,
    enviaBarra,
} from '../../db'
import store from '@/store/index'
import moment from 'moment'
import cateogrias from '@/components/dialogos/dialogocatalogo'
import lista_observ from '@/views/restaurante/lista_observ'
import agrega_producto from '@/views/restaurante/agrega_producto'
import cat_fijo from '@/components/catalogo_fijo'
import axios from "axios"
import {
    pdfprecuenta,
    pc_print,
    genera_precuenta
} from '../../pdf_precuentas'
import {
    genera_precuenta_v2
} from '../../pdf_precuentas_v2'
export default {
    components: {
        cateogrias,
        lista_observ,
        agrega_producto,
        cat_fijo
    },
    data() {
        return {
            dial_catalogo: false,
            dial_cliente: false,
            dialogAgrega: false,
            dialogoProducto: false,
            dialog_prede: false,
            listaproductos: [],
            cantidadEdita: '',
            observacion: '',
            permite_editar: false,
            item_selecto: '',
            responsable: '',
            documentos: ['DNI', 'RUC', 'Pasaporte', 'Carnet de Extranjeria'],
            documento: 'DNI',
            numero: '',
            nombreCompleto: '',
            direccion: '',
            telefono: '',
            tipocomprobante: 'T',
            cliente_selecto: [],
        }
    },
    async beforeCreate() {
        store.commit("dialogoprogress")
        var snapshot = await buscaComanda(this.$route.params.id).once("value")
        if (snapshot.exists()) {
            this.listaproductos = snapshot.val()
            store.commit("dialogoprogress")
        } else {
            store.commit("dialogoprogress")
        }
    },
    async created() {
        console.log(this.$route.params.res)
        this.responsable = this.$route.params.res
    },

    methods: {

        async abre_cliente() {
            var snapshot = await busca_mesa(this.$route.params.id).once('value')
            console.log(snapshot.val())
            if (snapshot.val().cliente) {
                this.cliente_selecto = snapshot.val().cliente
                this.documento = this.cliente_selecto.documento
                this.numero = this.cliente_selecto.numero
                this.nombreCompleto = this.cliente_selecto.nombreCompleto
                this.direccion = this.cliente_selecto.direccion
                this.telefono = this.cliente_selecto.telefono
                this.observacion = this.cliente_selecto.observacion
                this.tipocomprobante = this.cliente_selecto.tipocomprobante

            }
            this.dial_cliente = true
        },
        async guarda_cliente() {
            store.commit("dialogoprogress")
            var array = {
                documento: this.documento,
                numero: this.numero,
                nombreCompleto: this.nombreCompleto,
                direccion: this.direccion,
                telefono: this.telefono,
                observacion: this.observacion,
                tipocomprobante: this.tipocomprobante
            }
            await editaaMesa(this.$route.params.id + '/cliente', array)
            this.dial_cliente = false
            store.commit("dialogoprogress")
        },

        async grabaComanda() {
            store.commit("dialogoprogress")
            var lista_pendiente = this.listaproductos.filter(item => item.estado == 'pendiente')

            var array_cocina = lista_pendiente.filter(item => item.cocina == true)
            var array_barra = lista_pendiente.filter(item => item.barra == true)
            var array_cocina2 = lista_pendiente.filter(item => item.cocina2 == true)
            var array_barra2 = lista_pendiente.filter(item => item.barra2 == true)
            for (var i = 0; i < this.listaproductos.length; i++) {
                this.listaproductos[i].estado = 'enviado'
            }
            await Promise.all([
                editaaMesa(this.$route.params.id + '/estado', "OCUPADA"),
                nuevaComanda(this.$route.params.id, this.listaproductos)
            ])

            if (store.state.configImpresora.impresoracocina || store.state.configImpresora.impresora_auto) {
                await this.envio_impresora(array_cocina, array_barra, array_cocina2, array_barra2)
            } else {
                await this.envio_pantallas(array_cocina, array_barra, array_cocina2, array_barra2)
            }
            store.commit("dialogoprogress")
            this.regresa()
        },
        api_rest(data, metodo) {
            var a = axios({
                method: 'POST',
                url: 'https://us-central1-mitienda-f5ef8.cloudfunctions.net/prueba',
                //url: 'http://localhost:5000/mitienda-f5ef8/us-central1/prueba',
                headers: {},
                data: {
                    "bd": store.state.baseDatos.bd,
                    "data": data,
                    "metodo": metodo
                }
            }).then(response => {
                console.log(response.data)
                return response
            })
            return a
        },
        async envio_pantallas(cocina, barra, cocina2, barra2) {
            if (cocina != '') {
                var arrayenvio_cocina = {
                    fecha: moment().unix(),
                    usuario: store.state.permisos.nombre,
                    idmesa: this.$route.params.id,
                    data: cocina,
                    delivery: false
                }
                await enviaCocina(arrayenvio_cocina)
            }
            if (barra != '') {
                var arrayenvio_barra = {
                    fecha: moment().unix(),
                    usuario: store.state.permisos.nombre,
                    idmesa: this.$route.params.id,
                    data: barra,
                    delivery: false
                }
                await enviaBarra(arrayenvio_barra)
            }
            return true
        },
        async envio_impresora(cocina, barra, cocina2, barra2) {
            if (cocina == '' && barra == '' && cocina2 == '' && barra2 == '') {
                return
            }
            var fecha = moment(String(new Date())).format('YYYY-MM-DD hh:mm')

            if (store.state.configImpresora.impresora_auto) {
                var responsable = store.state.permisos.correo.substring(0, store.state.permisos.correo.indexOf('@'))
                const procesarPreCuenta = (area, array) => {
                    if (array.length > 0) {
                        console.log(area)
                        genera_precuenta_v2({
                            delivery: false,
                            id_mesa: this.$route.params.id
                        },
                            array,
                            '80',
                            false,
                            responsable,
                            area
                        );
                    }
                };
                procesarPreCuenta("Cocina1", cocina);
                procesarPreCuenta("Barra1", barra);
                procesarPreCuenta("Cocina2", cocina2);
                procesarPreCuenta("Barra2", barra2);

            } else {
                if (cocina != '') {
                    for (var i = 0; i < cocina.length; i++) {

                        cocina[i].observacion = cocina[i].observacion ? cocina[i].observacion.toString() : ''
                    }
                }
                if (barra != '') {
                    for (var i = 0; i < barra.length; i++) {
                        barra[i].observacion = barra[i].observacion ? barra[i].observacion.toString() : ''
                    }
                }
                if (cocina2 != '') {
                    for (var i = 0; i < cocina2.length; i++) {
                        cocina2[i].observacion = cocina2[i].observacion ? cocina2[i].observacion.toString() : ''
                    }
                }
                if (barra2 != '') {
                    for (var i = 0; i < barra2.length; i++) {
                        barra2[i].observacion = barra2[i].observacion ? barra2[i].observacion.toString() : ''
                    }
                }
                var a = await pc_print(this.$route.params.id, this.responsable,
                    fecha, 0, cocina, barra, cocina2, barra2)
                console.log(a)
                return true
            }

        },
        sumaTotal() {
            var suma = 0
            for (var i = 0; i < this.listaproductos.length; i++) {
                var data = this.listaproductos[i]
                if (data.operacion != 'GRATUITA') {
                    suma = suma + (data.cantidad * data.precio)
                }

            }
            return suma.toFixed(2)
        },
        abre_obs(val) {
            this.item_selecto = val
            this.dialog_prede = true
        },
        agregaCatalogo(value) {
            if (!Boolean(value.cocina2)) {
                value.cocina2 = false
            }
            if (!Boolean(value.barra2)) {
                value.barra2 = false
            }

            var arra = {
                uuid: this.create_UUID().substring(29),
                id: value.id,
                operacion: value.operacion,
                cantidad: value.cantidad,
                nombre: value.nombre,
                precio: value.precio,
                idmesa: this.$route.params.id,
                estado: 'pendiente',
                observacion: value.observacion || '',
                cocina: value.cocina,
                barra: value.barra,
                cocina2: value.cocina2,
                barra2: value.barra2,
            }
            this.listaproductos.push(arra)
            if (store.state.configuracion.stock_comandas) {
                var array = {
                    metodo: 'RESTA',
                    array: arra
                }
                this.api_rest(array, 'modifica_stock_unitario')
            }
        },

        graba_obs(val) {
            console.log(val);
            let pos = this.listaproductos.findIndex(e => e.uuid === this.item_selecto.uuid);
            if (pos !== -1) {
                let productoEditado = { ...this.listaproductos[pos], observacion: val };
                this.listaproductos.splice(pos, 1, productoEditado); // Reemplaza el objeto
            }
            this.dialog_prede = false;
        },

        editaProducto(val) {
            console.log(val)
            this.permite_editar = false
            if (val.estado == 'pendiente') {
                this.permite_editar = true
            } else {
                console.log(store.state.permisos.editar_comanda, !store.state.permisos.moduloempresa)
                if (!store.state.permisos.editar_comanda) {
                    this.permite_editar = false
                } else {
                    this.permite_editar = true
                }
            }

            this.item_selecto = val
            this.cantidadEdita = val.cantidad
            this.dialogoProducto = true
        },
        grabaEdita() {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            var invent = store.state.productos.find(item => item.id == this.item_selecto.id)
            if (store.state.configuracion.stock_comandas) {
                if (parseFloat(this.item_selecto.cantidad) > parseFloat(this.cantidadEdita)) {
                    var sum = parseFloat(this.item_selecto.cantidad) - parseFloat(this.cantidadEdita)
                    this.item_selecto.cantidad = sum
                    var array = {
                        metodo: 'SUMA',
                        array: this.item_selecto
                    }
                    this.api_rest(array, 'modifica_stock_unitario')
                } else {
                    var control = (this.cantidadEdita - this.item_selecto.cantidad)
                    var c = invent.stock - (control)
                    console.log(c)
                    if (c < 0) {
                        alert("NO TIENE STOCK SUFICIENTE")
                        return
                    }
                    if (parseFloat(this.item_selecto.cantidad) < parseFloat(this.cantidadEdita)) {
                        var sum = parseFloat(this.cantidadEdita) - parseFloat(this.item_selecto.cantidad)
                        this.item_selecto.cantidad = sum
                        var array = {
                            metodo: 'RESTA',
                            array: this.item_selecto
                        }
                        this.api_rest(array, 'modifica_stock_unitario')
                    }
                }
            }
            this.listaproductos[pos].cantidad = this.cantidadEdita
            this.dialogoProducto = false
        },
        eliminaedita() {
            var pos = this.listaproductos.map(e => e.uuid).indexOf(this.item_selecto.uuid)
            this.listaproductos.splice(pos, 1)
            if (store.state.configuracion.stock_comandas) {
                var array = {
                    metodo: 'SUMA',
                    array: this.item_selecto
                }
                this.api_rest(array, 'modifica_stock_unitario')
            }
            this.dialogoProducto = false
        },
        suma() {
            this.cantidadEdita = parseInt(this.cantidadEdita) + 1
        },
        resta() {
            if (this.cantidadEdita > 1) {
                this.cantidadEdita = parseInt(this.cantidadEdita) - 1
            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        regresa() {
            this.$router.push({
                name: 'lista_mesas'
            })
        },
        BuscarDocumento() {
            if (this.numero == '') {
                store.commit("dialogoClientesnuevos")
            } else {
                if (this.documento == "RUC" || this.documento == "DNI" &&
                    this.numero.length == 8 || this.numero.length == 11) {
                    store.commit("dialogoprogress")

                    if (this.numero.length == 11) {
                        this.documento = "RUC"
                    } else {
                        this.documento = "DNI"
                    }
                    var cliente = store.state.clientes.find(id => String(id.documento) === String(this.numero))
                    if (Boolean(cliente)) {
                        this.cliente_selecto = cliente
                        this.nombreCompleto = cliente.nombre
                        this.direccion = cliente.direccion
                        this.refcliente = cliente.referencia
                        this.telfcliente = cliente.telefono
                        store.commit("dialogoprogress")
                    } else {
                        this.consultaApiPeru()
                    }
                } else {
                    store.commit('dialogosnackbar', 'Documento Invalido')
                }
            }
        },
        agregacliente(data) {
            this.cliente_selecto = data
            this.documento = data.tipodoc
            this.numero = data.documento
            this.nombreCompleto = data.nombre
            this.telfcliente = data.telefono
            this.direccion = data.direccion
            this.refcliente = data.referencia
        },
        consultaApiPeru() {
            var self = this
            var token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress"),
                    self.llenardatos(response.data.data)
                ))
        },
        async llenardatos(data) {
            this.departamento = store.state.baseDatos.departamento
            this.provincia = store.state.baseDatos.provincia
            this.distrito = store.state.baseDatos.distrito
            this.ubigeo = store.state.baseDatos.ubigeo
            if (this.documento == 'DNI') {
                this.nombreCompleto = data.nombre_completo
                if (Boolean(data.direccion_completa)) {
                    this.direccion = data.direccion_completa
                }
            }
            if (this.documento == 'RUC') {
                this.nombreCompleto = data.nombre_o_razon_social
                if (Boolean(data.direccion_completa)) {
                    this.direccion = data.direccion_completa
                    this.departamento = data.departamento
                    this.provincia = data.provincia
                    this.distrito = data.distrito
                    this.ubigeo = data.ubigeo_sunat
                }
            }
        },
    }
}
</script>

<style scoped>
.text-h4 {
    font-size: 2rem;
}

.text-h5 {
    font-size: 1.5rem;
}

.pa-3 {
    padding: 1rem;
}

.elevation-6 {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
