<template>
    <div class="pa-3">

        <v-row dense>
            <v-col cols="5">
                <v-btn rounded elevation="6" block color="info" @click="obtenordenproducto()" small>
                    <v-icon left>
                        mdi-plus
                    </v-icon>Nuevo
                </v-btn>
            </v-col>
            <v-col cols="5">
                <v-btn rounded elevation="6" block color="error" to="/categorias" small>
                    <v-icon left>
                        mdi-plus
                    </v-icon>Categorias
                </v-btn>
            </v-col>
            <v-col cols="2">
                <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="red" text v-bind="attrs" v-on="on">
                            <v-icon left>
                                mdi-cog
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="subirXLS()">
                            <v-list-item-title>Exporta Excel</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="dialogoFiltro = true">
                            <v-list-item-title>Filtro Avanzado</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>

        </v-row>
        <v-row align="center">
            <v-col cols="6">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
            <v-col cols="6" class="mt-n6">
                <v-select outlined dense v-model="filtro_categoria" :items="arraycategoria_f" menu-props="auto"
                    hide-details label="Categoria"></v-select>
            </v-col>
        </v-row>

        <v-card class="pa-4">
            <v-data-table dense :headers="headers" :items="listafiltrada" mobile-breakpoint="1">
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="font-size:80%;">{{ item.id }}</td>
                        <td style="font-size:80%;">{{ item.categoria }}</td>
                        <td style="font-size:80%;">{{ item.nombre }}</td>
                        <td style="font-size:80%;">{{ item.stock }}</td>
                        <td style="font-size:80%;">{{ item.precio }}</td>
                        <td style="font-size:80%;">
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="green" @click="editar(item)">mdi-lead-pencil</v-icon>
                                </v-col>
                            </v-row>
                        </td>

                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogo" max-width="550px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    ID : {{ id }}
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="eliminar()">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6">
                        <v-select :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col v-if="$store.state.configuracion.codbarras" cols="12" md="12" sm="12">
                        <v-text-field dense class="mb-n7" v-model="codbarra" label="Cod Barras"
                            append-icon="mdi-barcode-scan" autofocus></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-autocomplete dense v-model="categoria" :items="arraycategoria" menu-props="auto" hide-details
                            label="Categoria" clearable></v-autocomplete>
                    </v-col>
                    <v-col cols="6" xs="6">
                        <v-select dense v-model="medida" :items="$store.state.medidas" menu-props="auto" hide-details
                            label="Medida"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n2">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="nombre" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6" xs="6">
                        <v-text-field type="number" dense v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="stock_min" label="Stock min"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n7">
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="precio" label="Precio"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense type="number" v-model="costo" label="Costo"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-3">

                    <v-col cols="12" class="mt-n7">
                        <v-select dense v-model="grupo_obs" :items="lista_obs" chips label="Grupo Obs."
                            multiple></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n10" v-if="$store.state.baseDatos.restaurante" dense>
                    <v-col cols="6">
                        <v-switch v-model="cocina" label="Cocina 1"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="barra" label="Barra 1"></v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-n10" v-if="$store.state.baseDatos.restaurante" dense>
                    <v-col cols="6">
                        <v-switch v-model="cocina2" label="Cocina 2"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="barra2" label="Barra 2"></v-switch>
                    </v-col>
                </v-row>

                <v-row class="mt-n10" dense>
                    <v-col cols="6">
                        <v-switch v-model="cargoxconsumo" label="Cargo x consumo"></v-switch>
                    </v-col>
                    <v-col cols="6">
                        <v-switch v-model="controstock" label="Control Stock"></v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-n10" dense>
                    <v-col cols="6">
                        <v-switch v-model="es_combo" label="Combo"></v-switch>
                    </v-col>
                </v-row>
                <v-simple-table class="mt-n5" v-if="es_combo" fixed-header dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Accion <v-btn color="success" x-small
                                        @click="dial_adiciona = !dial_adiciona">Adicionar</v-btn>
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in lista_combo" :key="item.id">
                                <td> <strong class="red--text"> {{ item.cantidad }} x</strong> {{ item.nom_producto }}
                                </td>
                                <td>
                                    <v-icon small color="red" @click="elimina_combo(item)">mdi-delete</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoFiltro" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoFiltro = !dialogoFiltro">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-card-text>
                    <v-select :items="arrayfiltroStock" label="Stock" outlined v-model="filtrostock"></v-select>
                    <v-select :items="arrayfiltroestado" label="ESTADO" outlined v-model="filtroestado"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="dialogoFiltro = !dialogoFiltro">FILTRAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_setting" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_setting = !dial_setting">mdi-close</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="ir_menu()">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/menu.png"></v-img>
                                <h5 block class="text-center pa-1">Configura Carta</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="transferir_carta()">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/transferir.png"></v-img>
                                <h5 block class="text-center pa-1">Cambia Carta</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_adiciona" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_adiciona = !dial_adiciona">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="" dense>
                    <v-col cols="12">
                        <v-text-field outlined type="number" dense v-model="cantidad_combo"
                            label="Cantidad"></v-text-field>
                    </v-col>
                </v-row>
                <v-autocomplete v-model="producto_sele" :items="$store.state.productos" item-text="nombre"
                    item-value="id" label="Buscar Productos" clearable menu-props="auto" outlined dense
                    append-icon="mdi-magnify">
                    <template v-slot:item="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>
                                <strong>{{ item.categoria }}</strong> - {{ item.nombre }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template></v-autocomplete>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="guardar_combo()">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    eliminaProducto,
    obtenContador,
    sumaContador,
    allCategorias,
    editaProducto,
} from '../../db'
import store from '@/store/index'
export default {

    data: () => ({
        headers: [{
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
        },
        {
            text: 'Categoria',
            value: 'categoria',
        },
        {
            text: 'Nombre',
            value: 'nombre',
        },
        {
            text: 'Stock',
            value: 'stock',
        },
        {
            text: 'Precio',
            value: 'precio',
        },
        {
            text: 'Actions',
            value: 'actions',
            sortable: false
        },
        ],
        filtro_categoria: 'TODOS',
        arraycategoria_f: [],
        sumacon: false,
        dialogoFiltro: false,
        dial_setting: false,
        buscar: '',
        desserts: [],
        id: '',
        categoria: '',
        nombre: '',
        medida: 'UNIDAD',
        stock: '',
        precio: '0',
        costo: '',
        cocina: '',
        arraycategoria: [],
        dialogo: false,
        arraytipoProducto: ['BIEN', 'SERVICIO'],
        arrayfiltroStock: ['incluir 0', 'excluir 0'],
        filtrostock: 'incluir 0',
        arrayfiltroestado: ['TODOS', 'ACTIVO', 'INACTIVO'],
        filtroestado: 'ACTIVO',
        tipoproducto: "BIEN",
        arrayOperacion: [
            'GRAVADA',
            'EXONERADA',
            'GRATUITA'
        ],
        operacion: "GRAVADA",
        icbper: false,
        controstock: false,
        codbarra: '',
        activo: true,
        barra: false,
        cargoxconsumo: false,
        item_selecto: [],
        stock_min: '',
        lista_obs: [],
        grupo_obs: [],
        cocina2: false,
        barra2: false,
        es_combo: false,
        lista_combo: [],
        dial_adiciona: false,
        cantidad_combo: 1,
        producto_sele: '',
    }),
    async beforeCreate() {
        var snapshot = await allCategorias().once("value")
        snapshot.forEach((item) => {
            this.arraycategoria_f.push(item.val().nombre)
            this.arraycategoria.push(item.val().nombre)
        })
        var snap = store.state.observaciones
        snap.forEach((item) => {
            this.lista_obs.push(item.nombre)
        })
    },
    created() {
        this.arraycategoria_f.push('TODOS')
    },

    computed: {
        listafiltrada() {
            this.desserts = store.state.productos
            if (this.filtrostock == 'excluir 0') {
                var filtro = this.desserts.filter((item) =>
                    (item.stock) > 0)
            } else {
                var filtro = this.desserts
            }

            if (this.filtroestado == 'TODOS') {
                var lista = filtro
            } else {
                if (this.filtroestado == 'ACTIVO') {
                    var lista = filtro.filter((item) =>
                        (item.activo) == true)
                } else {
                    var lista = filtro.filter((item) =>
                        (item.activo) == false)
                }
            }
            if (this.filtro_categoria == 'TODOS') {
                return lista.filter((item) => (item.id + item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
            } else {
                return lista.filter(item => item.categoria == this.filtro_categoria && (item.id + item.nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
            }
        }

    },

    methods: {
        async guardar_combo() {
            try {
                // Validar que se haya seleccionado un producto y se haya ingresado la cantidad y precio
                if (!this.cantidad_combo || !this.producto_sele) {
                    alert("Por favor completa todos los campos para agregar el producto al combo.");
                    return;
                }

                // Buscar el producto en el estado global y añadirlo a la lista
                const producto = store.state.productos.find(e => e.id == this.producto_sele);
                if (!producto) {
                    alert("El producto seleccionado no existe.");
                    return;
                }

                this.lista_combo.push({
                    cantidad: this.cantidad_combo,
                    cod_producto: this.producto_sele,
                    nom_producto: producto.nombre,
                    precio: 1,
                });

                // Resetear los campos después de guardar
                this.cantidad_combo = null;
                this.producto_sele = null;
                this.dial_adiciona = false;

            } catch (error) {
                console.error("Error al guardar el combo:", error);
            }
        },

        elimina_combo(item) {
            try {
                // Filtrar la lista para excluir el elemento que se desea eliminar
                this.lista_combo = this.lista_combo.filter(combo => combo !== item);

            } catch (error) {
                console.error("Error al eliminar el producto del combo:", error);
            }
        },

        async obtenordenproducto() {
            store.commit("dialogoprogress")
            this.sumacon = true
            var snapshot = await obtenContador().once("value")
            if (snapshot.exists()) {
                this.id = snapshot.val().ordenproducto
                this.codbarra = ''
                this.categoria = '1'
                this.nombre = ''
                this.medida = 'UNIDAD'
                this.stock = 1
                this.precio = 0
                this.costo = 0
                this.cocina = false
                this.barra = false
                this.tipoproducto = 'BIEN'
                this.operacion = 'GRAVADA'
                this.icbper = false
                this.dialogo = true
                this.activo = true
                this.controstock = false
                this.cargoxconsumo = false
                this.grupo_obs = []
                this.cocina2 = false
                this.barra2 = false
                this.stock_min = 1
                if (Boolean(store.state.configuracion.operacion)) {
                    this.operacion = store.state.configuracion.operacion
                }
            }
            store.commit("dialogoprogress")
        },
        editar(val) {
            console.log(val);

            // Desestructuración del objeto para asignar valores directamente
            const {
                id, activo, codbarra, categoria, nombre, medida, stock, precio,
                costo, cocina, tipoproducto, operacion, icbper, barra, controstock,
                cargoxconsumo, grupo_obs = [], stock_min = 1, cocina2 = false, barra2 = false,
                es_combo = false, lista_combo = []
            } = val;

            // Asignar valores al estado
            this.item_selecto = val;
            this.sumacon = false;
            this.id = id;
            this.activo = activo;
            this.codbarra = codbarra;
            this.categoria = categoria;
            this.nombre = nombre;
            this.medida = medida;
            this.stock = stock;
            this.precio = precio;
            this.costo = costo;
            this.cocina = cocina;
            this.tipoproducto = tipoproducto;
            this.operacion = operacion;
            this.icbper = icbper;
            this.barra = barra;
            this.controstock = controstock;
            this.cargoxconsumo = cargoxconsumo;
            this.grupo_obs = grupo_obs;
            this.stock_min = stock_min;
            this.cocina2 = cocina2;
            this.barra2 = barra2;
            this.es_combo = es_combo;
            this.lista_combo = lista_combo;
            this.dialogo = true;
        },
        async save() {
            if (this.stock == '') {
                this.stock = 0
            }
            if (this.costo == '') {
                this.costo = 0
            }
            if (this.categoria == 1 || this.categoria == '') {
                alert("DEBE CONSIDERAR UNA CATEGORIA VALIDA")
                return
            }
            if (this.precio == 0) {
                alert("EL PRECIO NO PUEDE SER IGUAL A 0")
                return
            }
            store.commit("dialogoprogress")
            editaProducto(this.id, "id", this.id)
            editaProducto(this.id, "activo", this.activo)
            editaProducto(this.id, "codbarra", this.codbarra)
            editaProducto(this.id, "nombre", this.nombre.trim())
            editaProducto(this.id, "categoria", this.categoria)
            editaProducto(this.id, "medida", this.medida)
            editaProducto(this.id, "stock", this.stock)
            editaProducto(this.id, "precio", this.precio)
            editaProducto(this.id, "costo", this.costo)
            editaProducto(this.id, "cocina", this.cocina)
            editaProducto(this.id, "tipoproducto", this.tipoproducto)
            editaProducto(this.id, "operacion", this.operacion)
            editaProducto(this.id, "icbper", this.icbper)
            editaProducto(this.id, "barra", this.barra)
            editaProducto(this.id, "controstock", this.controstock)
            editaProducto(this.id, "cargoxconsumo", this.cargoxconsumo)
            editaProducto(this.id, "grupo_obs", this.grupo_obs)
            editaProducto(this.id, "stock_min", this.stock_min)
            editaProducto(this.id, "cocina2", this.cocina2)
            editaProducto(this.id, "es_combo", this.es_combo)
            editaProducto(this.id, "lista_combo", this.lista_combo)
            await editaProducto(this.id, "barra2", this.barra2)
            if (this.sumacon == true) {
                this.sumacontador()
            }
            this.dialogo = false
            store.commit("dialogoprogress")
        },

        async eliminar() {
            if (confirm('SEGURO QUE DESEA ELIMINAR?')) {
                await eliminaProducto(this.item_selecto.id)
                this.dialogo = false
            }
        },
        sumacontador() {
            sumaContador("ordenproducto", parseInt(this.id) + 1)
        },
        subirXLS() {
            this.$router.push({
                name: "ImportaExporta"
            })
        },

    },
}
</script>
