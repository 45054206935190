<template>
  <div class="pa-3 mb-2">
    <v-card>
      <v-card-subtitle>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="black--text"> EMPRESA:</span> <span> {{ $store.state.baseDatos.bd }} - {{
              $store.state.baseDatos.name }}</span>
          </v-col>
          <v-col cols="12">
            <span class="black--text">RUC:</span> <span> {{ $store.state.baseDatos.ruc }}</span>
          </v-col>
          <v-col cols="12">
            <span class="black--text">DIRECCION:</span> <span> {{ $store.state.baseDatos.direccion }} -
              {{ $store.state.baseDatos.distrito }} - {{ $store.state.baseDatos.provincia }} -
              {{ $store.state.baseDatos.departamento }}</span>
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>

    <v-row class="mt-n1">
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogoLogoEmpresa')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/logo.png"></v-img>
            <h4 block class="text-center pa-1">Logo</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogomedidas')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/medidas.png"></v-img>
            <h4 block class="text-center pa-1">Und Medida</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogoConfiguracion')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/config.png"></v-img>
            <h4 block class="text-center pa-1">Config General</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogomodopago')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/pago.png"></v-img>
            <h4 block class="text-center pa-1">Tipo Pagos</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="dial_modo_pagos = true">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/message.png"></v-img>
            <h4 block class="text-center pa-1">QR Pagos</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogocontadores')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/correlativos.png"></v-img>
            <h4 block class="text-center pa-1">Correlativos</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogoSerieDocumentos')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/series.png"></v-img>
            <h4 block class="text-center pa-1">Serie Doc</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="dial_seriev2 = !dial_seriev2">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/series.png"></v-img>
            <h4 block class="text-center pa-1">Serie V2</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogoImpresora')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/printer.png"></v-img>
            <h4 block class="text-center pa-1">Impresoras</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogohost')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/printer.png"></v-img>
            <h4 block class="text-center pa-1">Host Impresora</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="dial_serv_impr = !dial_serv_impr">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/printer.png"></v-img>
            <h4 block class="text-center pa-1">Servicio Impresora</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="gestionUsuarios()">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/clientes.png"></v-img>
            <h4 block class="text-center pa-1">Usuarios</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="$store.commit('dialogoRestaurante')">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/menu.png"></v-img>
            <h4 block class="text-center pa-1">Config Restaurante</h4>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-3" md="3" xs="6">
        <v-card @click="gestionEmpleados()">
          <v-container>
            <v-img class="mx-auto" height="70" width="70" src="/asistente.png"></v-img>
            <h4 block class="text-center pa-1">Empleados</h4>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <qr_pagos v-if="dial_modo_pagos" @cierra="dial_modo_pagos = false" />
    <seriev2 v-if="dial_seriev2" @cierra="dial_seriev2 = false"></seriev2>
    <serv_impre v-if="dial_serv_impr" @cierra="dial_serv_impr = false"></serv_impre>
    <contadores />
    <undMedida />
    <Configuracion />
    <logoEmpresa />
    <Impresoras />
    <serieDocumentos />
    <mdopago />
    <restaurante />
    <impresorahost />
  </div>
</template>

<script>
import mdopago from '@/components/configEmpresa/mdopago'
import undMedida from '@/components/configEmpresa/undMedida'
import contadores from '@/components/configEmpresa/contadores'
import Configuracion from '@/components/configEmpresa/configuracion'
import logoEmpresa from '@/components/configEmpresa/logoEmpresa'
import Impresoras from '@/components/configEmpresa/impresoras'
import serieDocumentos from '@/components/configEmpresa/serieDocumentos'
import seriev2 from '@/components/configEmpresa/serie_v2'
import qr_pagos from '@/components/configEmpresa/graba_qr'
import restaurante from '@/components/configEmpresa/restaurante'
import impresorahost from '@/components/configEmpresa/impresorahost'
import serv_impre from '@/components/configEmpresa/servicio_impresora'
export default {
  name: 'empresa',

  components: {
    undMedida,
    contadores,
    Configuracion,
    logoEmpresa,
    Impresoras,
    serieDocumentos,
    mdopago,
    restaurante,
    impresorahost,
    qr_pagos,
    seriev2,
    serv_impre
  },

  data() {
    return {
      dial_seriev2: false,
      dial_modo_pagos: false,
      dial_serv_impr: false,
    }
  },
  methods: {
    gestionUsuarios() {
      this.$router.push({
        name: "gestionUsuarios"
      })
    },
    gestionEmpleados() {
      this.$router.push({
        name: "empleados"
      })
    }
  }

}
</script>
